import { useState, useEffect } from "react";
import {
  Loading,
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../../components/modules";

import {
  Container,
  Input,
  Text,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/base";
import {
  ScrollArea,
  Avatar,
  AvatarImage,
  AvatarFallback,
  Separator,
  useToast,
  Skeleton,
  Progress,
  Calendar,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../components/ui";
import { format } from "date-fns";
import Context from "../../Context";
import { IoSearch } from "react-icons/io5";
import { Calendar as CalendarIcon } from "lucide-react";
import { CgProfile } from "react-icons/cg";
import { RiMailSendFill, RiHandHeartFill } from "react-icons/ri";
import { FaTruck } from "react-icons/fa";
import { PiBankBold } from "react-icons/pi";
import { BsQrCode } from "react-icons/bs";
import { MdPersonSearch } from "react-icons/md";
import { BsEnvelopePaperHeart } from "react-icons/bs";
import { FaCommentMedical } from "react-icons/fa6";
import { IoTime } from "react-icons/io5";
import {
  FaTimes,
  FaCheckCircle,
  FaHourglassHalf,
  FaTimesCircle,
  FaMoneyBillAlt,
} from "react-icons/fa";
import { PiVaultBold } from "react-icons/pi";
import { MdAccountBalance } from "react-icons/md";
import { FiShare2 } from "react-icons/fi";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import { dateToUnix, unixToDate } from "../../util";
export const Orders = () => {
  const { toast } = useToast();
  const { orderId } = useParams();
  const { callApi, isDarkTheme, themes, configs, profileState } = Context();
  const location = useLocation();
  const { hash, pathname, search } = location;
  const [orders, setOrders] = useState(false);
  const [order, setOrder] = useState(false);
  const [selectedRoundDate, setSelectedRoundDate] = useState(configs.roundDate);
  const [orderSummary, setOrderSummary] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(
    orderId || localStorage.getItem("selectedOrder") || false
  );
  const [query, setQuery] = useState({ key: "", value: "" });

  const [user, setUser] = useState(false);
  const [isOpenConfirmSheet, setIsOpenConfirmSheet] = useState(false);
  const [date, setDate] = useState();

  useEffect(() => {
    getOrderList();
    getOrderSummary();
  }, [selectedRoundDate]);

  useEffect(() => {
    // if (selectedOrder) {
    selectedOrder && getOrder();
    // } else {
    //   console.log("false");
    //   setOrder(false);
    // }
  }, [selectedOrder]);

  const getOrderList = async (key, value) => {
    const queries =
      key && value
        ? [[key, value]]
        : query.key && query.value
        ? [[query.key, query.value]]
        : [];

    const result = await callApi("orders-getAll", {
      queries: queries,
      roundDate: selectedRoundDate,
      limit: 50,
      orderBy: ["createdAt", "desc"],
    });
    setOrders(result.length > 0 ? result : false);
    if (result.length === 1) {
      setSelectedOrder(result[0].id);
    }
  };

  const getUserInfo = async (selectedUser) => {
    const result = await callApi("users-getUserInfoAdmin", {
      userId: selectedUser,
    });

    setUser(result || false);
  };

  const getOrder = async () => {
    setOrder({ isLoading: true });
    setUser({ isLoading: true });
    const { order } = await callApi("orders-getOrderInfoAdmin", {
      id: selectedOrder,
    });
    if (!order) return;
    order.notes = order.notes ? order.notes.reverse() : [];

    setOrder(order);
    // getOrderList();
    if (!order.isGuest) {
      await getUserInfo(order.userId);
    } else {
      setUser(order);
    }
  };

  const getOrderSummary = async () => {
    const { result } = await callApi("orders-getOrderCount", {
      roundDate: selectedRoundDate,
    });

    setOrderSummary(() => result);
  };

  const handleClickSummary = (key, value) => {
    setOrders([]);
    getOrderList(key, value);
  };

  const handleSearch = () => {
    if (!query.value) {
      setQuery((prevState) => ({ ...prevState, key: false }));
      getOrderList();
    } else {
      if (new RegExp(/^\d+$|^$/).test(query.value)) {
        if (query.value.length >= 9) {
          getOrderList("phone", query.value);
        } else {
          getOrderList("userId", query.value);
        }
      } else if (query.value.length > 5) {
        getOrderList("firstName", query.value);
      } else {
        getOrderList("shortId", query.value);
      }
    }
  };

  const handleOrderEdit = async (key, value) => {
    setOrder({ isLoading: true });
    const { status, message } = await callApi("orders-edit", {
      id: order.id,
      key: key,
      value: value,
    });
    if (status) {
      toast({
        className: "bg-[rgb(59,176,101)]",
        title: (
          <div className="flex gap-2 items-center">
            <FaCheckCircle /> อัพเดตข้อมูลสำเร็จ
          </div>
        ),
      });
      setIsOpenConfirmSheet(false);
    } else {
      toast({
        className: "bg-[#c15b78]",
        title: (
          <div className="flex gap-2 items-center">
            <FaTimes /> อัพเดตข้อมูลไม่สำเร็จ
          </div>
        ),
        description: message || "-",
      });
    }
    getOrderList();
    getOrder();
  };

  const updatePrice = async (e) => {
    setOrder({ isLoading: true });
    const { status, message } = await callApi("orders-changePriceByAdmin", {
      newPrice: e || 0,
      orderId: order.id,
    });

    if (status) {
      toast({
        className: "bg-[rgb(59,176,101)]",
        title: `อัพเดตข้อมูลสำเร็จ`,
      });
      getOrder();
      setIsOpenConfirmSheet(false);
      return true;
    } else {
      toast({
        className: "bg-[#c15b78]",
        title: `อัพเดตข้อมูลไม่สำเร็จ`,
        description: message || "-",
      });
      getOrder();
      return false;
    }
  };

  const updateNotes = async (e) => {
    setOrder({ isLoading: true });
    const { status, message } = await callApi("orders-updateNotes", {
      note: e,
      orderId: order.id,
    });

    if (status) {
      toast({
        className: "bg-[rgb(59,176,101)]",
        title: `อัพเดตข้อมูลสำเร็จ`,
      });
    } else {
      toast({
        className: "bg-[#c15b78]",
        title: `อัพเดตข้อมูลไม่สำเร็จ`,
      });
    }
    getOrder();
  };

  return (
    <>
      <Dialog
        isOpenConfirmSheet={isOpenConfirmSheet}
        setIsOpenConfirmSheet={setIsOpenConfirmSheet}
      />
      <div className="grid grid-cols-5 gap-3 mb-3">
        <div
          className="py-2 px-3 w-full col-span-2 xl:col-span-1 bg-gray-950 text-white rounded-2xl hover:cursor-pointer hover:scale-[98%] duration-300"
          onClick={() => handleClickSummary("", "")}
        >
          <p className="text-sm font-medium">ทั้งหมด</p>
          <div className="text-2xl text-center mt-3 mb-4 font-bold">
            {!orderSummary ? <Loading /> : orderSummary?.all?.toLocaleString()}
          </div>
        </div>
        <div
          className="py-2 px-3 w-full bg-[rgb(59,176,101)] text-white rounded-2xl hover:cursor-pointer hover:scale-[98%]  duration-300"
          onClick={() => handleClickSummary("status", "approved")}
        >
          <p className="text-sm font-medium">อนุมัติ</p>
          <div className="text-2xl text-center mt-3 mb-4 font-bold">
            {!orderSummary ? (
              <Loading />
            ) : (
              orderSummary?.approved?.toLocaleString()
            )}
          </div>
        </div>
        <div
          className="py-2 px-3 w-full bg-[#37beb0] text-white rounded-2xl hover:cursor-pointer hover:scale-[98%] duration-300"
          onClick={() => handleClickSummary("status", "paid")}
        >
          <p className="text-sm font-medium">จ่ายแล้ว</p>
          <div className="text-2xl text-center mt-3 mb-4 font-bold">
            {!orderSummary ? <Loading /> : orderSummary?.paid?.toLocaleString()}
          </div>
        </div>
        <div
          className="py-2 px-3 w-full bg-[#ffb067] text-white rounded-2xl hover:cursor-pointer hover:scale-[98%] duration-300"
          onClick={() => handleClickSummary("status", "pending")}
        >
          <p className="text-sm font-medium">รอจ่าย</p>
          <div className="text-2xl text-center mt-3 mb-4 font-bold">
            {!orderSummary ? (
              <Loading />
            ) : (
              orderSummary?.pending?.toLocaleString()
            )}
          </div>
        </div>
        <div
          className="py-2 px-3 w-full bg-[#c15b78] text-white rounded-2xl hover:cursor-pointer hover:scale-[98%] duration-300"
          onClick={() => handleClickSummary("status", "canceled")}
        >
          <p className="text-sm font-medium">ยกเลิก</p>
          <div className="text-2xl text-center mt-3 mb-4 font-bold">
            {!orderSummary ? (
              <Loading />
            ) : (
              orderSummary?.canceled?.toLocaleString()
            )}
          </div>
        </div>
      </div>

      <div className="flex flex-row gap-3  duration-300">
        {/* order list */}
        <Container
          className={` p-1 w-full h-fit shadow-md duration-300 ${
            selectedOrder && "w-[300px]"
          }`}
        >
          {/* <Container className="h-[800px] w-full max-w-[300px] rounded-[20px] shadow-xl"> */}
          <div className={`flex flex-col gap-3 m-2`}>
            <div
              className={`flex ${
                selectedOrder && "flex-col"
              } items-center gap-2 mb-2`}
            >
              {/* <MdPersonSearch
                className={`absolute w-6 h-6 top-3 right-5 cursor-pointer duration-100 ${
                  isDarkTheme ? "text-white" : " hover:text-[rgb(214,212,212)]"
                } ${query.value ? "text-[rgb(214,212,212)]" : "text-input"}`}
                onClick={() => handleSearch()}
              />

              {query?.value && (
                <FaTimes
                  className={`absolute w-6 h-6 top-3 right-14 cursor-pointer duration-100 ${
                    isDarkTheme
                      ? "text-white"
                      : "text-[rgb(214,212,212)] hover:text-red-200"
                  } `}
                  onClick={() => setQuery({ key: "", value: "" })}
                />
              )} */}
              <Input
                className="rounded-lg"
                placeholder="ค้นหา"
                value={query.value || ""}
                onChange={(e) => {
                  setQuery((prevState) => ({
                    key: new RegExp(/^\d+$|^$/).test(e.target.value)
                      ? "phone"
                      : e.target.value?.length > 5
                      ? "firstName"
                      : "shortId",

                    value: e.target.value,
                  }));
                }}
                onKeyDown={(e) => e.key === "Enter" && handleSearch()}
              />

              {/* <div
                className={`flex gap-3 ${selectedOrder && "flex-col w-full"}`}
              > */}
              <Select
                value={query.key}
                onValueChange={(e) => {
                  setQuery((prevState) => ({
                    ...prevState,
                    key: e,
                  }));
                }}
              >
                <SelectTrigger
                  className={`my-1 w-full max-w-72 text-md font-medium rounded-lg ${
                    isDarkTheme ? "text-gray-50" : "text-gray-400"
                  }`}
                >
                  <SelectValue placeholder="ฟิลเตอร์" />
                </SelectTrigger>

                <SelectContent className="bg-black rounded-2xl">
                  <SelectItem
                    className="text-lg py-2 font-medium text-white bg-gray-950 hover:bg-gray-700"
                    value="shortId"
                  >
                    รหัสคำสั่งซื้อ
                  </SelectItem>
                  <SelectItem
                    className="text-lg py-2 font-medium text-white bg-gray-950 hover:bg-gray-700"
                    value="userId"
                  >
                    รหัสผู้ใช้
                  </SelectItem>
                  <SelectItem
                    className="  text-center text-lg font-medium text-white bg-gray-950 hover:bg-gray-700"
                    value="firstName"
                  >
                    ชื่อ
                  </SelectItem>
                  <SelectItem
                    className="text-lg font-medium text-white bg-gray-950 hover:bg-gray-700"
                    value="lastName"
                  >
                    นามสกุล
                  </SelectItem>
                  <SelectItem
                    className="text-lg py-2 font-medium text-white bg-gray-950 hover:bg-gray-700"
                    value="phone"
                  >
                    เบอร์โทรศัพท์
                  </SelectItem>
                </SelectContent>
              </Select>
              <button
                className={`h-12 ${
                  selectedOrder ? "w-full" : "w-52"
                } bg-[#168AFF] flex gap-1 justify-center items-center text-sm text-white hover:opacity-85 duration-200 disabled:hover:bg-gray-950 font-bold rounded-[0.5rem] disabled:cursor-not-allowed disabled:opacity-50`}
                onClick={() => handleSearch()}
              >
                <IoSearch />
                ค้นหา
              </button>
              {/* </div> */}
            </div>
            {/* <div>
              <Select
                defaultValue={selectedRoundDate}
                onValueChange={(e) => {
                  return setSelectedRoundDate(e);
                }}
              >
                <SelectTrigger
                  className={`my-1 w-44 text-md font-medium rounded-lg ${
                    isDarkTheme ? "text-gray-50" : "text-[rgb(26,29,32)]"
                  } ${selectedOrder && "w-fill"}`}
                >
                
                  <SelectValue placeholder={configs.roundDate} />
                </SelectTrigger>
                <SelectContent className="bg-black rounded-2xl">
                  {configs.roundDates.reverse().map((roundDate) => {
                    return (
                      <SelectItem
                        className="text-lg font-medium text-white bg-gray-950 hover:bg-gray-700"
                        value={roundDate}
                        key={roundDate}
                      >
                        {roundDate}
                      </SelectItem>
                    );
                  })}
                </SelectContent>
              </Select>
            </div> */}
          </div>

          {!orders ? (
            <div className="flex justify-center items-center h-72">
              ไม่พบสิ่งที่ค้นหา
            </div>
          ) : orders.length > 0 ? (
            <div className="m-2">
              <div
                className={`grid ${
                  selectedOrder ? "grid-cols-3" : "grid-cols-9"
                } bg-[rgb(228,228,228,0.5)] w-full gap-3 items-center p-3 rounded-xl`}
              >
                <p className="font-semibold text-center text-sm">คำสั่งซื้อ</p>
                {/* <div className="text-sm font-semibold text-center hidden col-span-2 sm:col-span-1 sm:block xl:hidden">
                  วันที่ซื้อ
                </div> */}
                <p
                  className={`${
                    selectedOrder && "hidden"
                  } font-semibold text-center text-sm`}
                >
                  วันที่ซื้อ
                </p>
                <p
                  className={`${
                    selectedOrder && "hidden"
                  } font-semibold text-center text-sm`}
                >
                  ชื่อ-นามสกุล
                </p>
                <p
                  className={`${
                    selectedOrder && "hidden"
                  } font-semibold text-center text-sm`}
                >
                  เบอร์โทรศัพท์
                </p>
                <p className={`font-semibold text-center text-sm`}>ช่องทาง</p>
                <p
                  className={`font-semibold text-center text-sm ${
                    selectedOrder && "hidden"
                  } `}
                >
                  การชำระ
                </p>

                <p
                  className={`font-semibold text-center text-sm ${
                    selectedOrder && "hidden"
                  } `}
                >
                  ราคา
                </p>
                <p
                  className={`font-semibold text-center text-sm ${
                    selectedOrder && "hidden"
                  } `}
                >
                  สลิป
                </p>
                <p className={`font-semibold text-center text-sm  `}>สถานะ</p>
              </div>
              <ScrollArea
                className={`relative  ${
                  orders.length > 5 ? `xl:h-[50dvh]` : "xl:h-fit"
                }
              } py-0 mt-2 mb-2`}
              >
                {orders.map((order, index) => {
                  return (
                    <OrderList
                      orderData={order}
                      isDarkTheme={isDarkTheme}
                      key={order.shortId}
                      setSelectedOrder={setSelectedOrder}
                      isSelectedOrder={selectedOrder === order.id}
                      selectedOrder={selectedOrder}
                      isNotLast={index !== orders.length - 1}
                    />
                  );
                })}
              </ScrollArea>
            </div>
          ) : (
            <div className="flex justify-center items-center h-72">
              <Loading />
            </div>
          )}
        </Container>
        {selectedOrder && (
          <div className="flex flex-col w-full sm:flex-row gap-3 duration-300">
            <div className="flex flex-col flex-grow h-fit gap-3">
              {/* order detail */}
              <Container className={`relative h-fit p-3 w-full duration-300`}>
                {order?.isLoading ? (
                  <div className="w-full h-72 flex justify-center items-center">
                    <Loading className="mx-6 border-[rgb(1,113,216)] border-r-[rgb(0,0,0,0)]" />
                  </div>
                ) : (
                  <>
                    {/* top right icon */}
                    <div
                      className={`absolute top-4 right-4 flex gap-3 justify-end`}
                    >
                      <FiShare2
                        onClick={() => {
                          navigator.clipboard.writeText(
                            `https://admin.hongthongofficial.com/orders?${order?.shortId}`
                          );

                          toast({
                            title: "คัดลอกสำเร็จ",
                            description: `https://admin.hongthongofficial.com/orders?${order?.shortId}`,
                          });
                        }}
                        className="cursor-pointer text-gray-400 duration-300 hover:text-gray-900"
                      />
                      <FaTimes
                        onClick={() => setSelectedOrder(false)}
                        className="cursor-pointer text-gray-400 duration-300 hover:text-red-600"
                      />
                    </div>
                    <Text className={`text-xl font-semibold`}>
                      คำสั่งซื้อ #{order?.shortId}
                    </Text>
                    {/* status bar */}
                    <div className="py-8">
                      <Text className={`text-sm font-semibold`}>
                        สถานะคำสั่งซื้อ
                      </Text>
                      {order?.status === "approved" && (
                        <>
                          <div className="w-full py-1.5 my-1 mb-2 bg-[rgb(59,176,101)] rounded-lg">
                            <Text
                              className={`text-white text-xs font-bold text-center`}
                            >
                              คำสั่งซื้อสำเร็จ
                            </Text>
                          </div>
                          <button
                            disabled={
                              profileState.role !== "dev" &&
                              profileState.role !== "superAdmin"
                            }
                            className={`bg-[#c15b78] flex-grow rounded-md px-3 py-2 text-xs text-white font-semibold hover:font-bold duration-200 hover:shadow-lg`}
                            onClick={() =>
                              setIsOpenConfirmSheet({
                                body: {
                                  text: `ยกเลิกการสั่งซื้อ?`,
                                  func: () =>
                                    handleOrderEdit("status", "canceled"),
                                },
                              })
                            }
                          >
                            ยกเลิกคำสั่งซื้อ
                          </button>
                        </>
                      )}

                      {order?.status === "canceled" && (
                        <div className="w-full py-1.5 my-1 bg-[#c15b78] rounded-lg">
                          <Text
                            className={`text-white text-xs font-bold text-center`}
                          >
                            คำสั่งถูกยกเลิก
                          </Text>
                        </div>
                      )}
                      {(order?.status === "pending" ||
                        order?.status === "paid") && (
                        <div className="relative my-3 flex items-center justify-around">
                          <Progress
                            idColor={
                              order?.status === "pending"
                                ? "bg-[#ffb067]"
                                : "bg-[#37beb0]"
                            }
                            value={order?.status === "pending" ? 25 : 75}
                          />
                          <div className="absolute grid grid-cols-2  justify-items-center w-full top-0">
                            <div className="flex flex-col items-center">
                              <div
                                className={`w-5 h-5 border -translate-y-1/3 rounded-full ${
                                  order?.status === "pending"
                                    ? "bg-[#ffb067]"
                                    : "bg-[#37beb0]"
                                } z-10`}
                              />
                              <Text
                                className={`text-xs font-bold ${
                                  order?.status === "pending"
                                    ? "text-[#ffb067]"
                                    : "text-[#37beb0]"
                                }`}
                              >
                                รอจ่ายเงิน
                              </Text>
                            </div>
                            <div className="flex flex-col items-center">
                              <div
                                className={`w-5 h-5 border -translate-y-1/3 rounded-full ${
                                  order?.status === "pending"
                                    ? "border-gray-500 bg-[rgb(208,208,211)]"
                                    : "bg-[#37beb0]"
                                } z-10`}
                              />
                              <Text
                                className={`text-xs font-bold ${
                                  order?.status === "pending"
                                    ? "text-primary/20"
                                    : "text-[#37beb0]"
                                }`}
                              >
                                รออนุมัติ
                              </Text>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    {order?.status === "paid" && (
                      <>
                        <Text
                          className={`text-sm font-semibold text-center mt-6 mb-2`}
                        >
                          คำสั่งซื้อรออนุมัติ
                        </Text>
                        <div className="flex w-full gap-2 flex-wrap mb-20">
                          <button
                            className={`bg-[#c15b78] flex-grow rounded-md px-3 py-2 text-xs text-white font-semibold hover:font-bold duration-200 hover:shadow-lg`}
                            onClick={() =>
                              setIsOpenConfirmSheet({
                                body: {
                                  text: `ยกเลิกการสั่งซื้อ?`,
                                  func: () =>
                                    handleOrderEdit("status", "canceled"),
                                },
                              })
                            }
                          >
                            ยกเลิกคำสั่งซื้อ
                          </button>
                          <button
                            className={`bg-[rgb(59,176,101)] flex-grow rounded-md px-3 py-2 text-xs text-white font-semibold hover:font-bold duration-200 hover:shadow-lg`}
                            onClick={() =>
                              setIsOpenConfirmSheet({
                                body: {
                                  text: `อนุมัติการสั่งซื้อ?`,
                                  func: () =>
                                    handleOrderEdit("status", "approved"),
                                },
                              })
                            }
                          >
                            อนุมัติคำสั่งซิ้อ
                          </button>
                        </div>
                      </>
                    )}
                    {order?.status === "pending" && (
                      <div>
                        <Text
                          className={`text-sm font-semibold text-center mt-6 mb-2`}
                        >
                          คำสั่งซื้อรอจ่ายเงิน
                        </Text>
                        <div className="flex w-full gap-2 flex-wrap mb-20">
                          <button
                            className={`bg-[#c15b78] flex-grow rounded-md px-3 py-2 text-xs text-white font-semibold hover:font-bold duration-200 hover:shadow-lg`}
                            onClick={() =>
                              setIsOpenConfirmSheet({
                                body: {
                                  text: `ยกเลิกการสั่งซื้อ?`,
                                  func: () =>
                                    handleOrderEdit("status", "canceled"),
                                },
                              })
                            }
                          >
                            ยกเลิกคำสั่งซื้อ
                          </button>
                          <button
                            className={`bg-[rgb(59,176,101)] flex-grow rounded-md px-3 py-2 text-xs text-white font-semibold hover:font-bold duration-200 hover:shadow-lg`}
                            onClick={() =>
                              setIsOpenConfirmSheet({
                                body: {
                                  text: `อนุมัติการสั่งซื้อ?`,
                                  func: () =>
                                    handleOrderEdit("status", "approved"),
                                },
                              })
                            }
                          >
                            อนุมัติคำสั่งซิ้อ
                          </button>
                        </div>
                      </div>
                    )}

                    <div className={`relative grid grid-cols-3 gap-3`}>
                      {/* shortId */}
                      <div>
                        <Text
                          className={`text-sm mb-1 font-medium text-gray-500`}
                        >
                          รหัสคำสั่งซื้อ
                        </Text>
                        <div className="flex items-center h-[45px]">
                          <Text className={`text-sm font-semibold`}>
                            {order?.shortId}
                          </Text>
                        </div>
                      </div>
                      {/* createdAtDate */}
                      <div>
                        <Text
                          className={`text-sm mb-1 font-medium text-gray-500`}
                        >
                          วัน-เวลาที่สร้าง
                        </Text>
                        <div className="flex items-center h-[45px]">
                          <Text className={`text-sm font-semibold`}>
                            {new Date(order?.createdAt).toLocaleDateString(
                              "th-TH",
                              {
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                                hour: "numeric",
                                minute: "numeric",
                              }
                            )}
                          </Text>
                        </div>
                      </div>
                      {/* expireDate */}
                      {order?.status !== "approved" && (
                        <div>
                          <Text
                            className={`text-sm mb-1 font-medium text-gray-500`}
                          >
                            วัน-เวลาที่หมดอายุ
                          </Text>
                          <div className="flex items-center h-[45px]">
                            <Text className={`text-sm font-semibold`}>
                              {new Date(order?.expireAt).toLocaleDateString(
                                "th-TH",
                                {
                                  year: "numeric",
                                  month: "long",
                                  day: "numeric",
                                  hour: "numeric",
                                  minute: "numeric",
                                }
                              )}
                            </Text>
                          </div>
                        </div>
                      )}
                      {/* paymentChannel */}
                      <div>
                        <Text
                          className={`text-sm mb-1 font-medium text-gray-500`}
                        >
                          ช่องทางการชำระ
                        </Text>

                        <div className="h-fit w-full ">
                          <Select
                            defaultValue={order?.paymentChannel}
                            onValueChange={(e) => {
                              const translate = {
                                bank: "โอนเงิน",
                                qr: "QR code",
                              };
                              setIsOpenConfirmSheet({
                                body: {
                                  text: `ต้องการแก้ไขช่องทางการชำระเงิน ${
                                    translate[order?.paymentChannel]
                                  } เป็น ${translate[e]} ?`,
                                  func: () =>
                                    handleOrderEdit("paymentChannel", e),
                                },
                              });
                            }}
                          >
                            <SelectTrigger className="my-1 text-md font-medium">
                              <SelectValue placeholder="-"></SelectValue>
                            </SelectTrigger>
                            <SelectContent className="bg-black">
                              <SelectItem
                                className="text-lg font-medium text-white bg-gray-950 hover:bg-gray-700"
                                value="bank"
                              >
                                <div className="flex gap-2 items-center">
                                  <PiBankBold className="text-[25px]" />
                                  <p className="text-[10px]">BANK</p>
                                </div>
                              </SelectItem>
                              <SelectItem
                                className="text-lg font-medium text-white bg-gray-950 hover:bg-gray-700"
                                value="qr"
                              >
                                <div className="flex gap-2 items-center">
                                  <BsQrCode className="text-[25px]" />
                                  <p className="text-[10px]">QR</p>
                                </div>
                              </SelectItem>
                            </SelectContent>
                          </Select>
                        </div>
                      </div>
                      {/* deliverChannel */}
                      <div>
                        <Text
                          className={`text-sm mb-1 font-medium text-gray-500`}
                        >
                          ช่องทางการจัดส่ง
                        </Text>

                        <div className="h-fit w-full">
                          <Select
                            value={order?.deliverChannel}
                            onValueChange={(e) => {
                              const translate = {
                                online: "ออนไลน์",
                                mail: "ไปรษณีย์",
                                offline: "รับเอง",
                              };
                              setIsOpenConfirmSheet({
                                body: {
                                  text: `ต้องการแก้ไขช่องทางการจัดส่งจาก ${
                                    translate[order?.deliverChannel]
                                  } เป็น ${translate[e]} ?`,
                                  func: () =>
                                    handleOrderEdit("deliverChannel", e),
                                },
                              });
                            }}
                          >
                            <SelectTrigger className="my-1 text-md font-medium">
                              <SelectValue placeholder="--ยังไม่ได้เลือก--" />
                            </SelectTrigger>
                            <SelectContent className="bg-black">
                              <SelectItem
                                className="text-lg font-medium text-white bg-gray-950 hover:bg-gray-700"
                                value="online"
                              >
                                <div className="flex gap-2 items-center">
                                  <PiVaultBold className="text-[20px]" />
                                  <p className="font-semibold text-sm">
                                    ออนไลน์
                                  </p>
                                </div>
                              </SelectItem>
                              <SelectItem
                                className="text-lg font-medium text-white bg-gray-950 hover:bg-gray-700"
                                value="mail"
                              >
                                <div className="flex gap-2 items-center">
                                  <RiMailSendFill className="text-[20px]" />
                                  <p className="font-semibold text-sm">
                                    ไปรษณีย์
                                  </p>
                                </div>
                              </SelectItem>
                              <SelectItem
                                className="text-lg font-medium text-white bg-gray-950 hover:bg-gray-700"
                                value="offline"
                              >
                                <div className="flex gap-2 items-center">
                                  <RiHandHeartFill className="text-[20px]" />
                                  <p className="font-semibold text-sm">
                                    รับเอง
                                  </p>
                                </div>
                              </SelectItem>
                            </SelectContent>
                          </Select>
                        </div>
                      </div>
                      {/* shippingStatus */}
                      {order?.deliverChannel === "mail" && (
                        <div>
                          <Text
                            className={`text-sm mb-1 font-medium text-gray-500`}
                          >
                            สถานะการจัดส่ง
                          </Text>
                          <div className="h-fit w-full ">
                            <Select
                              defaultValue={order?.shippingStatus}
                              onValueChange={(e) => {
                                const translate = {
                                  pending: "รอดำเนินการ",
                                  packing: "กำลังจัดส่ง",
                                  shipped: "จัดส่งแล้ว",
                                  canceled: "ยกเลิก",
                                };
                                setIsOpenConfirmSheet({
                                  body: {
                                    text: `ต้องการแก้ไขสถานะการจัดส่ง${
                                      translate[order?.paymentChannel]
                                    } เป็น ${translate[e]} ?`,
                                    func: () =>
                                      handleOrderEdit("shippingStatus", e),
                                  },
                                });
                              }}
                            >
                              <SelectTrigger className="my-1 text-md font-medium">
                                <SelectValue placeholder="-"></SelectValue>
                              </SelectTrigger>
                              <SelectContent className="bg-black">
                                <SelectItem
                                  className="text-lg font-medium text-white bg-gray-950 hover:bg-gray-700"
                                  value="pending"
                                >
                                  <div className="flex gap-2 items-center">
                                    <IoTime className="text-[20px]" />
                                    <p className="font-semibold text-sm">
                                      รอดำเนินการ
                                    </p>
                                  </div>
                                </SelectItem>
                                <SelectItem
                                  className="text-lg font-medium text-white bg-gray-950 hover:bg-gray-700"
                                  value="packing"
                                >
                                  <div className="flex gap-2 items-center">
                                    <BsEnvelopePaperHeart className="text-[20px]" />
                                    <p className="font-semibold text-sm">
                                      กำลังจัดส่ง
                                    </p>
                                  </div>
                                </SelectItem>
                                <SelectItem
                                  className="text-lg font-medium text-white bg-gray-950 hover:bg-gray-700"
                                  value="shipped"
                                >
                                  <div className="flex gap-2 items-center">
                                    <FaTruck className="text-[20px]" />
                                    <p className="font-semibold text-sm">
                                      จัดส่งแล้ว
                                    </p>
                                  </div>
                                </SelectItem>
                                <SelectItem
                                  className="text-lg font-medium text-white bg-gray-950 hover:bg-gray-700"
                                  value="canceled"
                                >
                                  <div className="flex gap-2 items-center">
                                    <FaTimes className="text-[20px]" />
                                    <p className="font-semibold text-sm">
                                      ยกเลิก
                                    </p>
                                  </div>
                                </SelectItem>
                              </SelectContent>
                            </Select>
                          </div>
                        </div>
                      )}
                      {/* shippingDate */}
                      {order?.deliverChannel === "mail" && (
                        <div>
                          <Text
                            className={`text-sm mb-1 font-medium text-gray-500`}
                          >
                            วันที่จัดส่ง
                          </Text>
                          <div className="h-full relative w-full">
                            <Popover>
                              <PopoverTrigger className="w-full">
                                <div
                                  variant={"outline"}
                                  className={
                                    "bg-white px-3 flex border border-input items-center h-12 py-2 w-full justify-start rounded-[0.5rem] font-normal"
                                  }
                                >
                                  <CalendarIcon className="h-4 w-4 mr-2 mb-1" />
                                  {order?.shippingDate ? (
                                    format(order?.shippingDate, "PPP")
                                  ) : (
                                    <Text>เลือกวันที่</Text>
                                  )}
                                </div>
                              </PopoverTrigger>
                              <PopoverContent className="w-auto p-0">
                                <Calendar
                                  mode="single"
                                  selected={order?.shippingDate}
                                  onSelect={(e) => {
                                    console.log("🚀 ~ e:", e);

                                    const selectedDate = dateToUnix(e);

                                    setIsOpenConfirmSheet({
                                      body: {
                                        text: `ต้องการแก้ไขสถานะการจัดส่ง ${[
                                          unixToDate(order?.shippingDate),
                                        ]} เป็น ${unixToDate(
                                          selectedDate + 25200000
                                        )} ?`,
                                        func: () =>
                                          handleOrderEdit(
                                            "shippingDate",
                                            selectedDate + 25200000
                                          ),
                                      },
                                    });
                                  }}
                                  initialFocus
                                />
                              </PopoverContent>
                            </Popover>
                          </div>
                        </div>
                      )}
                      {/* shippingTrack */}
                      {order?.deliverChannel === "mail" && (
                        <div>
                          <Text
                            className={`text-sm mb-1 font-medium text-gray-500`}
                          >
                            เลขติดตามการจัดส่ง
                          </Text>

                          <div className="flex gap-2 items-center">
                            <Input
                              type="text"
                              placeholder="เลขติดตามการจัดส่ง"
                              defaultValue={order?.shippingTrack}
                              onBlur={(e) => {
                                setIsOpenConfirmSheet({
                                  body: {
                                    text: `ต้องการแก้ไขเลขติดตามการจัดส่ง ${
                                      order?.shippingTrack || "-"
                                    } เป็น ${e.target.value}?`,
                                    func: () =>
                                      handleOrderEdit(
                                        "shippingTrack",
                                        e.target.value
                                      ),
                                  },
                                });
                              }}
                            />
                          </div>
                        </div>
                      )}
                      {/* lotto count */}
                      <div>
                        <Text
                          className={`text-sm mb-1 font-medium text-gray-500`}
                        >
                          จำนวนสลาก
                        </Text>
                        <div className="flex items-center h-[45px]">
                          <Text className={`text-sm font-semibold`}>
                            {order?.size} ใบ
                          </Text>
                        </div>
                      </div>
                      {/* price */}
                      <div>
                        <Text
                          className={`text-sm mb-1 font-medium text-gray-500`}
                        >
                          ราคา
                        </Text>

                        <div className="flex gap-2 items-center">
                          <Input
                            type="number"
                            className="w-24"
                            placeholder="ราคา"
                            defaultValue={order?.totalPrice}
                            onBlur={(e) => {
                              setIsOpenConfirmSheet({
                                body: {
                                  text: `ต้องการแก้ไขราคาจาก ${order?.totalPrice.toLocaleString()} บาท เป็น ${Number(
                                    e.target.value
                                  ).toLocaleString()} บาท`,
                                  func: () => updatePrice(e.target.value),
                                },
                              });
                            }}
                          />
                          <Text className={`text-sm font-semibold`}>บาท</Text>
                        </div>
                      </div>

                      {order?.approverName && (
                        <div>
                          <Text
                            className={`text-sm mb-1 font-medium text-gray-500`}
                          >
                            ผู้อนุมัติ
                          </Text>
                          <div className="flex items-center h-[45px]">
                            <Text className={`text-sm font-semibold`}>
                              {order?.approverName}{" "}
                              <span className="opacity-35">
                                #{order?.approver}
                              </span>
                            </Text>
                          </div>
                        </div>
                      )}
                      {order?.approvedAt && (
                        <div>
                          <Text
                            className={`text-sm mb-1 font-medium text-gray-500`}
                          >
                            วัน-เวลาที่อนุมัติ
                          </Text>
                          <div className="flex items-center h-[45px]">
                            <Text className={`text-sm font-semibold`}>
                              {new Date(order?.approvedAt).toLocaleDateString(
                                "th-TH",
                                {
                                  year: "numeric",
                                  month: "long",
                                  day: "numeric",
                                  hour: "numeric",
                                  minute: "numeric",
                                }
                              )}
                            </Text>
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </Container>
              {/* profile */}
              <Container
                className={`relative h-fit flex-grow p-3 duration-300`}
              >
                <div className={`flex justify-between flex-col gap-3`}>
                  <div className={`flex gap-3 items-center md:flex-row`}>
                    {user?.isLoading ? (
                      <Skeleton
                        className={`w-24 h-24 duration-300 rounded-full`}
                      />
                    ) : (
                      <Avatar className={"w-24 h-24 duration-300"}>
                        <AvatarImage
                          src={
                            user?.profileImage ||
                            "https://github.com/shadcn.png"
                          }
                        />
                        <AvatarFallback>
                          {user?.firstName?.slice(0, 2)}
                        </AvatarFallback>
                      </Avatar>
                    )}
                    {user.isLoading ? (
                      <div>
                        <Skeleton className="h-4 m-1 w-[250px]" />
                        <Skeleton className="h-4 m-1 w-[250px]" />
                      </div>
                    ) : (
                      <div className="flex flex-col justify-center gap-1 items-center md:items-start">
                        <div className="flex 2xl:items-center gap-1 2xl:gap-3 flex-col 2xl:flex-row ">
                          <Text
                            className={`text-xl sm:text-xl font-bold center`}
                          >
                            {`${user?.firstName} ${user?.lastName}`}{" "}
                          </Text>
                          <Text className={`text-sm text-gray-400 font-bold`}>
                            #{user?.userId}
                          </Text>
                        </div>
                        <Text
                          className={`text-sm mb-3 text-gray-400 font-bold`}
                        >
                          {user?.phone}
                        </Text>
                        <div className="flex gap-2">
                          <button
                            className={`bg-[rgb(1,113,216)] w-fit rounded-md px-3 py-2 text-xs text-white font-semibold hover:font-bold duration-200 hover:shadow-lg`}
                            onClick={async () => {
                              let reasult = await callApi(
                                "users-addAdminToken",
                                {
                                  userId: user.userId,
                                }
                              );

                              window.open(
                                // constant.webConfigs.customerUrl +
                                "https://shop.hongthongofficial.com/" +
                                  "orders?adminToken=" +
                                  reasult.adminToken
                              );
                            }}
                          >
                            เข้าสู่ระบบด้วยบัญชีนี้
                          </button>
                          <button
                            className={`bg-[#c15b78] w-fit rounded-md px-3 py-2 text-xs text-white font-semibold hover:font-bold duration-200 hover:shadow-lg`}
                            onClick={async () => {
                              window.open(
                                // constant.webConfigs.customerUrl +
                                "https://admin.hongthongofficial.com/users?" +
                                  user.userId
                              );
                            }}
                          >
                            แก้ไข
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="mt-3">
                    <Separator />
                    <div>
                      <div className="flex flex-col justify-start items-start">
                        <div className="w-full">
                          <div className="flex ml-2 gap-2 items-center">
                            <CgProfile className={themes.text} />
                            <Text className="text-md font-bold my-3 ">
                              ข้อมูลส่วนตัว
                            </Text>
                          </div>
                          {/* <div className="flex flex-wrap flex-grow flex-col w-fit items-start sm:flex-row"> */}
                          {user.isLoading ? (
                            <div className="w-full h-64 flex items-center justify-center">
                              <Loading />
                            </div>
                          ) : (
                            <div className="grid grid-cols-1 md:grid-cols-2">
                              <div className="p-3 h-fit break-all w-full">
                                <Text className={"text-sm font-medium mb-2"}>
                                  ชื่อ - นามสกุล
                                </Text>
                                <Text className={"text-sm font-semibold"}>
                                  {user?.firstName} {user?.lastName}
                                </Text>
                              </div>
                              <div className="p-3 h-fit break-all w-full">
                                <Text className={"text-sm font-medium mb-2"}>
                                  เบอร์โทรศัพท์
                                </Text>
                                <Text className={"text-sm font-semibold"}>
                                  {user?.phone}
                                </Text>
                              </div>
                              <div className="p-3 h-fit break-all w-full">
                                <Text className={"text-sm font-medium mb-2"}>
                                  สัญชาติ
                                </Text>
                                <Text className={"text-sm font-bold"}>
                                  {user?.nationality === "TH"
                                    ? "ไทย"
                                    : user?.nationality === "LAO"
                                    ? "ลาว"
                                    : user?.nationality}
                                </Text>
                              </div>
                              <div className="p-3 h-fit break-all w-full">
                                <Text className={"text-sm font-medium mb-2"}>
                                  อีเมล์
                                </Text>
                                <Text className={"text-sm font-semibold"}>
                                  {user?.email || "-"}
                                </Text>
                              </div>
                              <div className="p-3 h-fit break-all w-full">
                                <Text className={"text-sm font-medium mb-2"}>
                                  ไลน์ไอดี
                                </Text>
                                <Text className={"text-sm font-semibold"}>
                                  {user?.lineId || "-"}
                                </Text>
                              </div>{" "}
                              <div className="p-3 h-fit break-all w-full">
                                <Text className={"text-sm font-medium mb-2"}>
                                  ชื่อไลน์
                                </Text>
                                <Text className={"text-sm font-semibold"}>
                                  {user?.lineTitle || "-"}
                                </Text>
                              </div>
                              <div className="p-3 h-fit w-full ">
                                <Text className={"text-sm font-medium mb-2"}>
                                  ที่อยู่
                                </Text>
                                <Text className={"text-sm font-semibold"}>
                                  {user?.address?.addr || "-"}
                                </Text>
                              </div>
                              <div className="p-3 h-fit break-all w-full">
                                <Text className={"text-sm font-medium mb-2"}>
                                  จังหวัด
                                </Text>
                                <Text className={"text-sm font-semibold"}>
                                  {user?.address?.province || "-"}
                                </Text>
                              </div>
                              <div className="p-3 h-fit  w-full">
                                <Text className={"text-sm font-medium mb-2"}>
                                  รหัสไปรษณีย์
                                </Text>
                                <Text className={"text-sm font-semibold"}>
                                  {user?.address?.zipcode || "-"}
                                </Text>
                              </div>
                            </div>
                          )}
                        </div>
                        <Separator className="mt-3 mr-[10px]" />
                        <div className="flex ml-2 my-3 gap-2 items-center">
                          <MdAccountBalance className={themes.text} />
                          <Text className="text-md font-bold">บัญชีธนาคาร</Text>
                        </div>
                        {user.isLoading ? (
                          <div className="w-full h-64 flex items-center justify-center">
                            <Loading />
                          </div>
                        ) : (
                          <div className="grid grid-cols-1 w-full md:grid-cols-2">
                            <div className=" p-3 h-fit break-all">
                              <Text className={"text-sm font-medium mb-2"}>
                                ธนาคาร
                              </Text>
                              <Text className={"text-sm font-semibold"}>
                                {user?.bank?.name || "-"}
                              </Text>
                            </div>
                            <div className=" p-3 h-fit break-all">
                              <Text className={"text-sm font-medium mb-2"}>
                                ชื่อบัญชี
                              </Text>
                              <Text className={"text-sm font-semibold"}>
                                {user?.bank?.accName || "-"}
                              </Text>
                            </div>
                            <div className=" p-3 h-fit break-all">
                              <Text className={"text-sm font-medium mb-2"}>
                                เลขที่บัญชี
                              </Text>
                              <Text className={"text-sm font-semibold"}>
                                {user?.bank?.accNo || "-"}
                              </Text>
                            </div>
                            <div className=" p-3 h-fit break-all">
                              <Text className={"text-sm font-medium mb-2"}>
                                ค่าเงินที่ใช้
                              </Text>
                              <Text className={"text-sm font-semibold"}>
                                {user?.bank?.currency || "-"}
                              </Text>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Container>
            </div>
            <div
              className={`${
                order?.isLoading ? "hidden" : "flex"
              } flex-col items-end gap-3 w-full max-w-72 hover:flex-grow hover:max-w-md duration-300`}
            >
              {/* slips */}
              <Container className={`w-full p-3 duration-300`}>
                <Text className={`text-xl mb-3 font-semibold`}>
                  หลักฐานการชำระเงิน
                </Text>
                {order?.slips?.length > 0 ? (
                  <img
                    className="w-full mx-auto rounded-xl "
                    src={order?.slips}
                    alt="slips"
                  />
                ) : (
                  <div className="flex flex-col items-center py-24 cursor-pointer opacity-10 hover:opacity-100 hover:text-gray-950 hover:font-bold duration-300">
                    <BsQrCode className="text-8xl mb-2" />
                    <Text className="text-sm">แนบสลิป</Text>
                  </div>
                )}
              </Container>
              {/* lotteries */}
              <Container className={`w-full p-3 duration-300`}>
                <Text className={`text-xl mb-3 font-semibold`}>
                  สลากทั้งหมด {order?.lotteries?.length} ใบ
                </Text>
                <ScrollArea
                  className={`relative ${
                    order?.lotteries?.length > 4
                      ? "h-80  hover:h-[900px]"
                      : "h-fit"
                  } duration-150 `}
                >
                  {order?.lotteries?.map((lotto, index) => {
                    return (
                      <img
                        className={`w-full my-1 z-10 mx-auto rounded-xl`}
                        src={lotto?.image}
                        key={lotto.id}
                        alt="lotto"
                      />
                    );
                  })}
                </ScrollArea>
              </Container>
              {/* notes */}
              <Container
                className={`  ${
                  isDarkTheme ? "bg-[rgb(255,255,255)]" : "bg-[rgb(26,29,32)]"
                } w-full p-3  duration-300`}
              >
                <Text
                  className={` ${
                    isDarkTheme ? "text-grey-900" : "text-[rgb(255,255,255)]"
                  } text-xl mb-3 font-semibold`}
                >
                  บันทึกเพิ่มเติม
                </Text>
                <div className={`relative mb-3 w-full flex-grow`}>
                  <FaCommentMedical
                    className={`absolute w-6 h-6 top-3 right-5 cursor-pointer duration-100 ${
                      isDarkTheme
                        ? "text-white"
                        : " hover:text-[rgb(214,212,212)]"
                    } ${
                      query.value ? "text-[rgb(214,212,212)]" : "text-input"
                    }`}
                    onClick={() => updateNotes()}
                  />

                  <Input
                    className={`rounded-2xl ${themes.bg.foreground}`}
                    placeholder="บันทึก"
                    defaultValue=""
                    onKeyDown={(e) =>
                      e.key === "Enter" && updateNotes(e.target.value)
                    }
                  />
                </div>
                {order?.notes?.length > 0 ? (
                  <div className="flex flex-col gap-2">
                    <ScrollArea
                      className={`relative ${
                        order?.notes?.length > 6 ? "h-80" : "h-fit"
                      }`}
                    >
                      {order?.notes?.map((eachNote) => {
                        return (
                          <Container
                            className="p-2 mb-2 rounded-xl"
                            key={eachNote.time}
                          >
                            <Text className="text-xs text-gray-700 font-bold">
                              {eachNote?.by}
                            </Text>
                            <Text className="text-xs text-gray-500 font-medium mb-2">
                              {new Date(eachNote?.time).toLocaleDateString(
                                "th-TH",
                                {
                                  year: "numeric",
                                  month: "long",
                                  day: "numeric",
                                  hour: "numeric",
                                  minute: "numeric",
                                }
                              )}
                            </Text>
                            <Text className={`text-sm font-semibold`}>
                              {eachNote?.note}
                            </Text>
                          </Container>
                        );
                      })}
                    </ScrollArea>
                  </div>
                ) : (
                  <Text className="text-gray-500 py-24 text-sm text-center">
                    ไม่พบบันทึก
                  </Text>
                )}
              </Container>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const OrderList = ({
  orderData,
  isDarkTheme,
  setSelectedOrder,
  isSelectedOrder,
  selectedOrder,
  isNotLast,
}) => {
  const handleSelectUser = (id) => {
    if (isSelectedOrder) {
      setSelectedOrder(false);
      localStorage.removeItem("selectedOrder");
    } else {
      localStorage.setItem("selectedOrder", orderData.id);
      setSelectedOrder(orderData.id);
    }
  };

  return (
    <div>
      {/* {selectedOrder ? ( */}
      <div
        className={`grid ${
          selectedOrder ? "grid-cols-3" : "grid-cols-9"
        } w-full gap-3 items-center py-3 rounded-xl duration-100 cursor-pointer ${
          isSelectedOrder
            ? isDarkTheme
              ? "text-[rgb(26,29,32)] bg-white"
              : "bg-[rgb(26,29,32)] text-gray-50"
            : isDarkTheme
            ? "text-gray-50 hover:bg-white hover:text-[rgb(26,29,32)]"
            : "hover:bg-[rgb(26,29,32)] hover:text-gray-50"
        }`}
        onClick={() => {
          handleSelectUser();
        }}
      >
        <p className={`text-sm text-center font-semibold truncate`}>
          {orderData?.shortId}
        </p>

        <p
          className={`text-xs ${
            selectedOrder && "hidden"
          } text-center font-semibold truncate`}
        >
          {new Date(orderData.createdAt).toLocaleDateString("th-TH", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
          })}{" "}
          น.
        </p>
        <p
          className={` ${
            selectedOrder && "hidden"
          } text-sm  font-semibold truncate`}
        >
          {orderData?.firstName + " " + orderData?.lastName}
        </p>
        <p
          className={` ${
            selectedOrder && "hidden"
          } text-sm text-center font-semibold truncate`}
        >
          {orderData?.phone}
        </p>

        <div
          className={`flex ${
            selectedOrder && "hidden"
          } justify-center text-sm font-semibold  truncate`}
        >
          {orderData?.deliverChannel === "online" ? (
            <div className="flex flex-col items-center">
              <PiVaultBold className="text-[25px]" />
              <p className="text-[10px]">ออนไลน์</p>
            </div>
          ) : orderData?.deliverChannel === "mail" ? (
            <div className="flex flex-col items-center">
              <RiMailSendFill className="text-[25px]" />
              <p className="text-[10px]">ไปรษณีย์</p>
            </div>
          ) : (
            <div className="flex flex-col items-center">
              <RiHandHeartFill className="text-[25px]" />
              <p className="text-[10px]">รับเอง</p>
            </div>
          )}
        </div>

        <div className={`flex justify-center`}>
          <div className={`text-sm font-semibold  xl:max-w-48 truncate`}>
            {orderData?.paymentChannel === "bank" ? (
              <div className="flex flex-col items-center">
                <PiBankBold className="text-[25px]" />
                <p className="text-[10px]">BANK</p>
              </div>
            ) : orderData?.paymentChannel === "qr" ? (
              <div className="flex flex-col items-center">
                <BsQrCode className="text-[25px]" />
                <p className="text-[10px]">QR</p>
              </div>
            ) : (
              "-"
            )}
          </div>
        </div>
        <p
          className={` ${
            selectedOrder && "hidden"
          } text-sm text-center font-semibold truncate`}
        >
          {orderData?.totalPrice.toLocaleString()} บาท
        </p>
        <div className={`flex justify-center ${selectedOrder && "hidden"} `}>
          {orderData?.slips ? (
            <img className="h-10" src={orderData?.slips} alt="slips" />
          ) : (
            "-"
          )}
        </div>
        <div className="flex justify-center">
          <div className={`text-sm font-semibold  xl:max-w-48 truncate`}>
            {orderData.status === "approved" && (
              <div className="flex flex-col w-14 gap-1 justify-center items-center">
                <FaCheckCircle className="text-lg text-[rgb(59,176,101)]" />
                <p className="text-[10px] font-bold text-[rgb(59,176,101)]">
                  อนุมัติ
                </p>
              </div>
            )}
            {orderData.status === "pending" && (
              <div className="flex flex-col w-14 gap-1 justify-center items-center">
                <FaHourglassHalf className="text-lg text-[#ffb067]" />
                <p className="text-[10px] font-bold text-[#ffb067]">
                  รอดำเนินการ
                </p>
              </div>
            )}
            {orderData.status === "canceled" && (
              <div className="flex flex-col w-14 gap-1 justify-center items-center">
                <FaTimesCircle className="text-lg text-[#c15b78]" />
                <p className="text-[10px] font-bold text-[#c15b78]">ยกเลิก</p>
              </div>
            )}
            {orderData.status === "paid" && (
              <div className="flex flex-col w-14 gap-1 justify-center items-center">
                <FaMoneyBillAlt className="text-lg text-[#37beb0]" />
                <p className="text-[10px] font-bold text-[#37beb0]">
                  จ่ายเงินแล้ว
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* ) : (
        <div
          className={`grid grid-cols-4 sm:grid-cols-7 md:grid-cols-6 xl:grid-cols-12 w-full gap-3 items-center p-3 rounded-xl duration-100 cursor-pointer ${
            isSelectedOrder
              ? isDarkTheme
                ? "text-[rgb(26,29,32)] bg-white "
                : "bg-[rgb(26,29,32)] text-gray-50 "
              : isDarkTheme
              ? "text-gray-50 hover:bg-white hover:text-[rgb(26,29,32)]"
              : "hover:bg-[rgb(26,29,32)] hover:text-gray-50"
          }`}
          onClick={() => {
            handleSelectUser();
          }}
        >
          <div className="">
            <p className={`text-sm font-semibold xl:max-w-48 truncate`}>
              {orderData?.shortId}
            </p>
          </div>
          <div className="justify-center col-span-2 md:col-span-1 hidden sm:flex ">
            <p
              className={`text-xs font-medium truncate hover:overflow-visible`}
            >
              {new Date(orderData.createdAt).toLocaleDateString("th-TH", {
                year: "numeric",
                month: "numeric",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
              })}
            </p>
          </div>
          <div className="text-center hidden xl:inline-block">
            <p className={`text-sm font-medium xl:max-w-48 truncate`}>
              {orderData?.userId}
            </p>
          </div>
          <div className="text-center hidden xl:inline-block">
            <p className={`text-sm font-medium xl:max-w-48 truncate`}>
              {orderData?.firstName}
            </p>
          </div>
          <div className="text-center hidden xl:inline-block">
            <p className={`text-sm font-medium  xl:max-w-48 truncate`}>
              {orderData?.lastName}
            </p>
          </div>
          <div className="text-center hidden xl:inline-block ">
            <p
              className={`text-sm font-medium  xl:max-w-48 truncate hover:overflow-visible`}
            >
              {orderData?.phone}
            </p>
          </div>
          <div className="text-center hidden xl:inline-block">
            <p className={`text-sm font-medium  xl:max-w-48 truncate`}>
              {orderData?.size}
            </p>
          </div>
          <div className="text-center hidden xl:inline-block">
            <p className={`text-sm font-semibold  xl:max-w-48 truncate`}>
              {orderData?.totalPrice?.toLocaleString()}
            </p>
          </div>
          <div className="text-center ">
            <div className={`text-sm font-semibold  xl:max-w-48 truncate`}>
              {orderData?.deliverChannel === "online" ? (
                <div className="flex flex-col items-center">
                  <PiVaultBold className="text-[25px]" />
                  <p className="text-[10px]">ออนไลน์</p>
                </div>
              ) : orderData?.deliverChannel === "mail" ? (
                <div className="flex flex-col items-center">
                  <RiMailSendFill className="text-[25px]" />
                  <p className="text-[10px]">ไปรษณีย์</p>
                </div>
              ) : (
                <div className="flex flex-col items-center">
                  <RiHandHeartFill className="text-[25px]" />
                  <p className="text-[10px]">รับเอง</p>
                </div>
              )}
            </div>
          </div>
          <div className=" flex justify-center">
            <div className={`text-sm font-semibold  xl:max-w-48 truncate`}>
              {orderData?.paymentChannel === "bank" ? (
                <div className="flex flex-col items-center">
                  <PiBankBold className="text-[25px]" />
                  <p className="text-[10px]">BANK</p>
                </div>
              ) : orderData?.paymentChannel === "qr" ? (
                <div className="flex flex-col items-center">
                  <BsQrCode className="text-[25px]" />
                  <p className="text-[10px]">QR</p>
                </div>
              ) : (
                "-"
              )}
            </div>
          </div>
          <div className="justify-center hidden sm:flex">
            {orderData?.slips ? (
              <img
                className="h-10 hover:-translate-y-1/2 hover:absolute hover:h-96 hover:z-10"
                src={orderData?.slips}
                alt="slips"
              />
            ) : (
              "-"
            )}
          </div>
          <div className="flex justify-center">
            <div className={`text-sm font-semibold  xl:max-w-48 truncate`}>
              {orderData.status === "approved" && (
                <div className="flex flex-col w-14 gap-1 justify-center items-center">
                  <FaCheckCircle className="text-lg text-[rgb(59,176,101)]" />
                  <p className="text-[10px] font-bold text-[rgb(59,176,101)]">
                    อนุมัติ
                  </p>
                </div>
              )}
              {orderData.status === "pending" && (
                <div className="flex flex-col w-14 gap-1 justify-center items-center">
                  <FaHourglassHalf className="text-lg text-[#ffb067]" />
                  <p className="text-[10px] font-bold text-[#ffb067]">
                    รอดำเนินการ
                  </p>
                </div>
              )}
              {orderData.status === "canceled" && (
                <div className="flex flex-col w-14 gap-1 justify-center items-center">
                  <FaTimesCircle className="text-lg text-[#c15b78]" />
                  <p className="text-[10px] font-bold text-[#c15b78]">ยกเลิก</p>
                </div>
              )}
              {orderData.status === "paid" && (
                <div className="flex flex-col w-14 gap-1 justify-center items-center">
                  <FaMoneyBillAlt className="text-lg text-[#37beb0]" />
                  <p className="text-[10px] font-bold text-[#37beb0]">
                    จ่ายเงินแล้ว
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      )} */}
      {isNotLast && <Separator className="my-2" />}
    </div>
  );
};

export const Dialog = ({ isOpenConfirmSheet, setIsOpenConfirmSheet }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    setIsLoading(true);
    await isOpenConfirmSheet?.body?.func();

    setIsLoading(false);
  };
  return (
    <AlertDialog
      open={isOpenConfirmSheet}
      onOpenChange={() => setIsOpenConfirmSheet(!isOpenConfirmSheet)}
    >
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>ยืนยันการแก้ไข</AlertDialogTitle>
        </AlertDialogHeader>
        <AlertDialogDescription className={`text-center`}>
          {isOpenConfirmSheet?.body?.text}
        </AlertDialogDescription>
        <AlertDialogFooter>
          <AlertDialogCancel
            className={`bg-white border-0`}
            onClick={() => setIsOpenConfirmSheet(false)}
          >
            ยกเลิก
          </AlertDialogCancel>
          <AlertDialogAction
            className={`bg-[rgb(59,176,101)]`}
            onClick={() => handleSubmit()}
          >
            {isLoading ? <Loading className={`h-3 w-3`} /> : "ยืนยัน"}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
