import { useState, useEffect } from "react";
import {
  Loading,
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../../components/modules";

import {
  Container,
  Input,
  Text,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  SelectGroup,
  TimePickerDemo,
} from "../../components/base";
import {
  ScrollArea,
  Avatar,
  AvatarImage,
  AvatarFallback,
  Separator,
  Skeleton,
  Progress,
  Calendar,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  useToast,
} from "../../components/ui";
import Context from "../../Context";
import constant from "../../constants";
import { resizeImage, unixToDateAndTime } from "../../util";
import { FaRegImages } from "react-icons/fa";

import { IoMdCalendar } from "react-icons/io";
import { FaPlus } from "react-icons/fa6";
import { BiImageAdd } from "react-icons/bi";
import { MdDeleteForever } from "react-icons/md";

export const ManageCoupon = () => {
  const { toast } = useToast();
  const { callApi, isDarkTheme, themes, configs, profileState } = Context();
  const [couponData, setCouponData] = useState(false);
  const [roundDate, setRoundDate] = useState(configs.roundDate);
  const [selectedCoupon, setSelectedCoupon] = useState(false);
  const [createCoupon, setCreateCoupon] = useState(false);
  useEffect(() => {
    fetchCoupon();
  }, [roundDate]);

  const fetchCoupon = async () => {
    const result = await callApi("coupons-getAllCoupons");

    const filterdData = result?.reduce((acc, coupon) => {
      const { type } = coupon;
      acc[type] ? acc[type].push(coupon) : (acc[type] = [coupon]);
      return acc;
    }, {});

    setCouponData(filterdData || false);
  };

  return (
    <div className="flex w-full gap-3 items-start duration-300">
      <Container
        className={`${selectedCoupon || createCoupon ? "w-1/4" : "w-full"} p-0`}
      >
        <div className="flex w-full justify-between gap-3 p-3">
          <Text className="text-2xl font-semibold">คูปองทั้งหมด</Text>
          <button
            className={`h-10 w-32 bg-[#168AFF] flex gap-1 justify-center items-center text-sm text-white hover:opacity-85 duration-200 disabled:hover:bg-gray-950 font-bold rounded-[0.5rem] disabled:cursor-not-allowed disabled:opacity-50`}
            onClick={() => setCreateCoupon(true)}
          >
            <FaPlus />
            สร้างคูปอง
          </button>
        </div>
        {couponData ? (
          <ScrollArea className={`h-[60dvh]`}>
            <Accordion
              className="p-3"
              type="multiple"
              defaultValue={["discount", "voucher", "reserve"]}
            >
              <AccordionItem className="border-b-0" value="discount">
                <AccordionTrigger className="bg-gray-900 px-3 rounded-md text-white">
                  <Text className={`text-base font-semibold text-white`}>
                    คูปองส่วนลด
                  </Text>
                </AccordionTrigger>
                <AccordionContent>
                  <div className={`flex flex-col`}>
                    <div
                      className={`grid h-14 ${
                        selectedCoupon || createCoupon
                          ? "hidden"
                          : "grid-cols-7"
                      }`}
                    >
                      <div className={`flex items-center justify-center `}>
                        <Text
                          className={`text-center mb-1 font-bold text-gray-500`}
                        >
                          วันหมดอายุ
                        </Text>
                      </div>
                      <div className={`flex items-center justify-center `}>
                        <Text
                          className={`text-center mb-1 font-bold text-gray-500`}
                        >
                          คูปองไอดี
                        </Text>
                      </div>
                      <div className="flex items-center justify-center">
                        <Text
                          className={`text-center mb-1 font-bold text-gray-500`}
                        >
                          ชื่อคูปอง
                        </Text>
                      </div>

                      <div className={`flex items-center justify-center `}>
                        <Text
                          className={`text-center mb-1 font-bold text-gray-500`}
                        >
                          ราคาขาย
                        </Text>
                      </div>

                      <div className={`flex items-center justify-center `}>
                        <Text
                          className={`text-center mb-1 font-bold text-gray-500`}
                        >
                          วิธีชำระ
                        </Text>
                      </div>
                      <div />
                      <div className={`flex items-center justify-center `}>
                        <Text
                          className={`text-center mb-1 font-bold text-gray-500`}
                        >
                          สถานะ
                        </Text>
                      </div>
                    </div>
                    {couponData.discount
                      ?.sort((a, b) => a.price - b.price)
                      .map((coupon, index) => (
                        <div
                          key={coupon.shortId}
                          className={`px-2 hover:cursor-pointer hover:bg-slate-100 duration-150 ${
                            selectedCoupon.shortId === coupon.shortId &&
                            "bg-[#37beb0] rounded-lg text-white"
                          }`}
                          onClick={() =>
                            selectedCoupon.shortId === coupon.shortId
                              ? setSelectedCoupon(false)
                              : setSelectedCoupon(coupon)
                          }
                        >
                          <div
                            className={`grid ${
                              selectedCoupon || createCoupon
                                ? "grid-cols-1"
                                : "grid-cols-7"
                            }`}
                          >
                            <div
                              className={`h-14 flex justify-center items-center ${
                                (selectedCoupon || createCoupon) && "hidden"
                              } `}
                            >
                              <Text
                                className={`text-sm font-semibold  ${
                                  Date.now() > coupon?.expireAt &&
                                  "text-red-500"
                                }`}
                              >
                                {new Date(coupon?.expireAt).toLocaleDateString(
                                  "th-TH",
                                  {
                                    year: "numeric",
                                    month: "long",
                                    day: "numeric",
                                    hour: "numeric",
                                    minute: "numeric",
                                  }
                                )}{" "}
                                น. {Date.now() > coupon?.expireAt && "หมดอายุ"}
                              </Text>
                            </div>
                            <div
                              className={`h-14 flex justify-center items-center ${
                                (selectedCoupon || createCoupon) && "hidden"
                              } `}
                            >
                              <Text
                                className={`text-sm font-semibold truncate`}
                              >
                                {coupon.shortId}
                              </Text>
                            </div>
                            <div className="h-14 flex justify-center items-center">
                              <Text
                                className={`text-sm font-semibold truncate ${
                                  selectedCoupon.shortId === coupon.shortId &&
                                  " text-white"
                                }`}
                              >
                                {coupon.name}
                              </Text>
                            </div>
                            <div
                              className={`h-14 flex justify-center items-center ${
                                (selectedCoupon || createCoupon) && "hidden"
                              } `}
                            >
                              <Text
                                className={`text-sm font-semibold truncate`}
                              >
                                {coupon.price.toLocaleString()}
                              </Text>
                            </div>
                            <div
                              className={`h-14 flex justify-center items-center ${
                                (selectedCoupon || createCoupon) && "hidden"
                              } `}
                            >
                              <Text
                                className={`text-sm font-semibold truncate`}
                              >
                                {coupon.isPointRedeem ? "แลกคะแนน" : "โอนเงิน"}
                              </Text>
                            </div>
                            <div
                              className={`h-14 flex justify-center items-center ${
                                (selectedCoupon || createCoupon) && "hidden"
                              } `}
                            >
                              {coupon.previewImage ? (
                                <img
                                  className="max-h-10"
                                  src={coupon.previewImage}
                                  alt={coupon.shortId}
                                />
                              ) : (
                                "-"
                              )}
                            </div>
                            <div
                              className={`h-14 flex justify-center items-center ${
                                (selectedCoupon || createCoupon) && "hidden"
                              }`}
                            >
                              <div
                                className={`bg-[${
                                  coupon.status === "active"
                                    ? "rgb(59,176,101)"
                                    : coupon.status === "inactive"
                                    ? "#c15b78"
                                    : "#37beb0"
                                }] text-white font-semibold rounded-md px-2 py-1 `}
                              >
                                <p>
                                  {constant.statusesTranslate[coupon.status]}
                                </p>
                              </div>
                            </div>
                          </div>
                          {couponData.discount?.length !== index + 1 && (
                            <Separator className="m-0 p-0" />
                          )}
                        </div>
                      ))}
                  </div>
                </AccordionContent>
              </AccordionItem>
              <AccordionItem className="border-b-0" value="reserve">
                <AccordionTrigger className="bg-gray-900 px-3 rounded-md text-white">
                  <Text className={`text-base font-semibold text-white`}>
                    คูปองจองสิทธ์
                  </Text>
                </AccordionTrigger>
                <AccordionContent>
                  <div className={`flex flex-col`}>
                    <div
                      className={`grid h-14 ${
                        selectedCoupon || createCoupon
                          ? "hidden"
                          : "grid-cols-7"
                      }`}
                    >
                      <div className={`flex items-center justify-center `}>
                        <Text
                          className={`text-center mb-1 font-bold text-gray-500`}
                        >
                          วันหมดอายุ
                        </Text>
                      </div>
                      <div className={`flex items-center justify-center `}>
                        <Text
                          className={`text-center mb-1 font-bold text-gray-500`}
                        >
                          คูปองไอดี
                        </Text>
                      </div>
                      <div className="flex items-center justify-center">
                        <Text
                          className={`text-center mb-1 font-bold text-gray-500`}
                        >
                          ชื่อคูปอง
                        </Text>
                      </div>

                      <div className={`flex items-center justify-center `}>
                        <Text
                          className={`text-center mb-1 font-bold text-gray-500`}
                        >
                          ราคาขาย
                        </Text>
                      </div>

                      <div className={`flex items-center justify-center `}>
                        <Text
                          className={`text-center mb-1 font-bold text-gray-500`}
                        >
                          วิธีชำระ
                        </Text>
                      </div>
                      <div />
                      <div className={`flex items-center justify-center `}>
                        <Text
                          className={`text-center mb-1 font-bold text-gray-500`}
                        >
                          สถานะ
                        </Text>
                      </div>
                    </div>
                    {couponData.reserve
                      ?.sort((a, b) => a.price - b.price)
                      .map((coupon, index) => (
                        <div
                          key={coupon.shortId}
                          className={`px-2 hover:cursor-pointer hover:bg-slate-100 duration-150 ${
                            selectedCoupon.shortId === coupon.shortId &&
                            "bg-[#37beb0] rounded-lg text-white"
                          }`}
                          onClick={() =>
                            selectedCoupon.shortId === coupon.shortId
                              ? setSelectedCoupon(false)
                              : setSelectedCoupon(coupon)
                          }
                        >
                          <div
                            className={`grid ${
                              selectedCoupon || createCoupon
                                ? "grid-cols-1"
                                : "grid-cols-7"
                            }`}
                          >
                            <div
                              className={`h-14 flex justify-center items-center ${
                                (selectedCoupon || createCoupon) && "hidden"
                              } `}
                            >
                              <Text
                                className={`text-sm font-semibold  ${
                                  Date.now() > coupon?.expireAt &&
                                  "text-red-500"
                                }`}
                              >
                                {new Date(coupon?.expireAt).toLocaleDateString(
                                  "th-TH",
                                  {
                                    year: "numeric",
                                    month: "long",
                                    day: "numeric",
                                    hour: "numeric",
                                    minute: "numeric",
                                  }
                                )}{" "}
                                น. {Date.now() > coupon?.expireAt && "หมดอายุ"}
                              </Text>
                            </div>
                            <div
                              className={`h-14 flex justify-center items-center ${
                                (selectedCoupon || createCoupon) && "hidden"
                              } `}
                            >
                              <Text
                                className={`text-sm font-semibold truncate`}
                              >
                                {coupon.shortId}
                              </Text>
                            </div>
                            <div className="h-14 flex justify-center items-center">
                              <Text
                                className={`text-sm font-semibold truncate ${
                                  selectedCoupon.shortId === coupon.shortId &&
                                  " text-white"
                                }`}
                              >
                                {coupon.name}
                              </Text>
                            </div>
                            <div
                              className={`h-14 flex justify-center items-center ${
                                (selectedCoupon || createCoupon) && "hidden"
                              } `}
                            >
                              <Text
                                className={`text-sm font-semibold truncate`}
                              >
                                {coupon.price.toLocaleString()}
                              </Text>
                            </div>
                            <div
                              className={`h-14 flex justify-center items-center ${
                                (selectedCoupon || createCoupon) && "hidden"
                              } `}
                            >
                              <Text
                                className={`text-sm font-semibold truncate`}
                              >
                                {coupon.isPointRedeem ? "แลกคะแนน" : "โอนเงิน"}
                              </Text>
                            </div>
                            <div
                              className={`h-14 flex justify-center items-center ${
                                (selectedCoupon || createCoupon) && "hidden"
                              } `}
                            >
                              {coupon.previewImage ? (
                                <img
                                  className="max-h-10"
                                  src={coupon.previewImage}
                                  alt={coupon.shortId}
                                />
                              ) : (
                                "-"
                              )}
                            </div>
                            <div
                              className={`h-14 flex justify-center items-center ${
                                (selectedCoupon || createCoupon) && "hidden"
                              }`}
                            >
                              <div
                                className={`bg-[${
                                  coupon.status === "active"
                                    ? "rgb(59,176,101)"
                                    : coupon.status === "inactive"
                                    ? "#c15b78"
                                    : "#37beb0"
                                }] text-white font-semibold rounded-md px-2 py-1 `}
                              >
                                <p>
                                  {constant.statusesTranslate[coupon.status]}
                                </p>
                              </div>
                            </div>
                          </div>
                          {couponData.reserve?.length !== index + 1 && (
                            <Separator className="m-0 p-0" />
                          )}
                        </div>
                      ))}
                  </div>
                </AccordionContent>
              </AccordionItem>
              <AccordionItem className="border-b-0" value="voucher">
                <AccordionTrigger className="bg-gray-900 px-3 rounded-md text-white">
                  <Text className={`text-base font-semibold text-white`}>
                    คูปองวอยเชอร์
                  </Text>
                </AccordionTrigger>
                <AccordionContent>
                  <div className={`flex flex-col`}>
                    <div
                      className={`grid h-14 ${
                        selectedCoupon || createCoupon
                          ? "hidden"
                          : "grid-cols-7"
                      }`}
                    >
                      <div className={`flex items-center justify-center `}>
                        <Text
                          className={`text-center mb-1 font-bold text-gray-500`}
                        >
                          วันหมดอายุ
                        </Text>
                      </div>
                      <div className={`flex items-center justify-center `}>
                        <Text
                          className={`text-center mb-1 font-bold text-gray-500`}
                        >
                          คูปองไอดี
                        </Text>
                      </div>
                      <div className={`flex items-center justify-center`}>
                        <Text className={` mb-1 font-bold text-gray-500`}>
                          ชื่อคูปอง
                        </Text>
                      </div>

                      <div className={`flex items-center justify-center `}>
                        <Text
                          className={`text-center mb-1 font-bold text-gray-500`}
                        >
                          ราคาขาย
                        </Text>
                      </div>

                      <div className={`flex items-center justify-center `}>
                        <Text
                          className={`text-center mb-1 font-bold text-gray-500`}
                        >
                          วิธีชำระ
                        </Text>
                      </div>
                      <div></div>
                      <div className={`flex items-center justify-center `}>
                        <Text
                          className={`text-center mb-1 font-bold text-gray-500`}
                        >
                          สถานะ
                        </Text>
                      </div>
                    </div>
                    {couponData.voucher
                      ?.sort((a, b) => a.price - b.price)
                      .map((coupon, index) => (
                        <div
                          key={coupon.shortId}
                          className={`px-2 hover:cursor-pointer hover:bg-slate-100 duration-150 ${
                            selectedCoupon.shortId === coupon.shortId &&
                            "bg-[#37beb0] rounded-lg text-white"
                          }`}
                          onClick={() =>
                            selectedCoupon.shortId === coupon.shortId
                              ? setSelectedCoupon(false)
                              : setSelectedCoupon(coupon)
                          }
                        >
                          <div
                            className={`grid ${
                              selectedCoupon || createCoupon
                                ? "grid-cols-1"
                                : "grid-cols-7"
                            }`}
                          >
                            <div
                              className={`h-14 flex justify-center items-center ${
                                (selectedCoupon || createCoupon) && "hidden"
                              } `}
                            >
                              <Text
                                className={`text-sm font-semibold  ${
                                  Date.now() > coupon?.expireAt &&
                                  "text-red-500"
                                }`}
                              >
                                {new Date(coupon?.expireAt).toLocaleDateString(
                                  "th-TH",
                                  {
                                    year: "numeric",
                                    month: "long",
                                    day: "numeric",
                                    hour: "numeric",
                                    minute: "numeric",
                                  }
                                )}{" "}
                                น.
                                {Date.now() > coupon?.expireAt && "หมดอายุ"}
                              </Text>
                            </div>
                            <div
                              className={`h-14 flex justify-center items-center ${
                                (selectedCoupon || createCoupon) && "hidden"
                              } `}
                            >
                              <Text
                                className={`text-sm font-semibold truncate`}
                              >
                                {coupon.shortId}
                              </Text>
                            </div>
                            <div className="h-14 flex justify-center items-center">
                              <Text
                                className={`text-sm font-semibold truncate $ ${
                                  selectedCoupon.shortId === coupon.shortId &&
                                  " text-white"
                                }`}
                              >
                                {coupon.name}
                              </Text>
                            </div>
                            <div
                              className={`h-14 flex justify-center items-center ${
                                (selectedCoupon || createCoupon) && "hidden"
                              } `}
                            >
                              <Text
                                className={`text-sm font-semibold truncate`}
                              >
                                {coupon.price.toLocaleString()}
                              </Text>
                            </div>
                            <div
                              className={`h-14 flex justify-center items-center ${
                                (selectedCoupon || createCoupon) && "hidden"
                              } `}
                            >
                              <Text
                                className={`text-sm font-semibold truncate`}
                              >
                                {coupon.isPointRedeem ? "แลกคะแนน" : "โอนเงิน"}
                              </Text>
                            </div>
                            <div
                              className={`h-14 flex justify-center items-center ${
                                (selectedCoupon || createCoupon) && "hidden"
                              } `}
                            >
                              {coupon.previewImage ? (
                                <img
                                  className="max-h-10"
                                  src={coupon.previewImage}
                                  alt={coupon.shortId}
                                />
                              ) : (
                                "-"
                              )}
                            </div>
                            <div
                              className={`h-14 flex justify-center items-center ${
                                (selectedCoupon || createCoupon) && "hidden"
                              }`}
                            >
                              <div
                                className={`bg-[${
                                  coupon.status === "active"
                                    ? "rgb(59,176,101)"
                                    : coupon.status === "inactive"
                                    ? "#c15b78"
                                    : "#37beb0"
                                }] text-white font-semibold rounded-md px-2 py-1 `}
                              >
                                <p>
                                  {constant.statusesTranslate[coupon.status]}
                                </p>
                              </div>
                            </div>
                          </div>
                          {couponData.voucher?.length !== index + 1 && (
                            <Separator className="m-0 p-0" />
                          )}
                        </div>
                      ))}
                  </div>
                </AccordionContent>
              </AccordionItem>

              <AccordionItem className="border-b-0" value="notify">
                <AccordionTrigger className="bg-gray-900 px-3 rounded-md text-white">
                  <Text className={`text-base font-semibold text-white`}>
                    คูปองแจ้งเตือน
                  </Text>
                </AccordionTrigger>
                <AccordionContent>
                  <div className={`flex flex-col`}>
                    <div
                      className={`grid h-14 ${
                        selectedCoupon || createCoupon
                          ? "hidden"
                          : "grid-cols-7"
                      }`}
                    >
                      <div className={`flex items-center justify-center `}>
                        <Text
                          className={`text-center mb-1 font-bold text-gray-500`}
                        >
                          วันหมดอายุ
                        </Text>
                      </div>
                      <div className={`flex items-center justify-center `}>
                        <Text
                          className={`text-center mb-1 font-bold text-gray-500`}
                        >
                          คูปองไอดี
                        </Text>
                      </div>
                      <div className={`flex items-center justify-center`}>
                        <Text className={` mb-1 font-bold text-gray-500`}>
                          ชื่อคูปอง
                        </Text>
                      </div>

                      <div className={`flex items-center justify-center `}>
                        <Text
                          className={`text-center mb-1 font-bold text-gray-500`}
                        >
                          ราคาขาย
                        </Text>
                      </div>

                      <div className={`flex items-center justify-center `}>
                        <Text
                          className={`text-center mb-1 font-bold text-gray-500`}
                        >
                          วิธีชำระ
                        </Text>
                      </div>
                      <div></div>
                      <div className={`flex items-center justify-center `}>
                        <Text
                          className={`text-center mb-1 font-bold text-gray-500`}
                        >
                          สถานะ
                        </Text>
                      </div>
                    </div>
                    {couponData.notify
                      ?.sort((a, b) => a.price - b.price)
                      .map((coupon, index) => (
                        <div
                          key={coupon.shortId}
                          className={`px-2 hover:cursor-pointer hover:bg-slate-100 duration-150 ${
                            selectedCoupon.shortId === coupon.shortId &&
                            "bg-[#37beb0] rounded-lg text-white"
                          }`}
                          onClick={() =>
                            selectedCoupon.shortId === coupon.shortId
                              ? setSelectedCoupon(false)
                              : setSelectedCoupon(coupon)
                          }
                        >
                          <div
                            className={`grid ${
                              selectedCoupon || createCoupon
                                ? "grid-cols-1"
                                : "grid-cols-7"
                            }`}
                          >
                            <div
                              className={`h-14 flex justify-center items-center ${
                                (selectedCoupon || createCoupon) && "hidden"
                              } `}
                            >
                              <Text
                                className={`text-sm font-semibold  ${
                                  Date.now() > coupon?.expireAt &&
                                  "text-red-500"
                                }`}
                              >
                                {new Date(coupon?.expireAt).toLocaleDateString(
                                  "th-TH",
                                  {
                                    year: "numeric",
                                    month: "long",
                                    day: "numeric",
                                    hour: "numeric",
                                    minute: "numeric",
                                  }
                                )}{" "}
                                น.
                                {Date.now() > coupon?.expireAt && "หมดอายุ"}
                              </Text>
                            </div>
                            <div
                              className={`h-14 flex justify-center items-center ${
                                (selectedCoupon || createCoupon) && "hidden"
                              } `}
                            >
                              <Text
                                className={`text-sm font-semibold truncate`}
                              >
                                {coupon.shortId}
                              </Text>
                            </div>
                            <div className="h-14 flex justify-center items-center">
                              <Text
                                className={`text-sm font-semibold truncate $ ${
                                  selectedCoupon.shortId === coupon.shortId &&
                                  " text-white"
                                }`}
                              >
                                {coupon.name}
                              </Text>
                            </div>
                            <div
                              className={`h-14 flex justify-center items-center ${
                                (selectedCoupon || createCoupon) && "hidden"
                              } `}
                            >
                              <Text
                                className={`text-sm font-semibold truncate`}
                              >
                                {coupon.price.toLocaleString()}
                              </Text>
                            </div>
                            <div
                              className={`h-14 flex justify-center items-center ${
                                (selectedCoupon || createCoupon) && "hidden"
                              } `}
                            >
                              <Text
                                className={`text-sm font-semibold truncate`}
                              >
                                {coupon.isPointRedeem ? "แลกคะแนน" : "โอนเงิน"}
                              </Text>
                            </div>
                            <div
                              className={`h-14 flex justify-center items-center ${
                                (selectedCoupon || createCoupon) && "hidden"
                              } `}
                            >
                              {coupon.previewImage ? (
                                <img
                                  className="max-h-10"
                                  src={coupon.previewImage}
                                  alt={coupon.shortId}
                                />
                              ) : (
                                "-"
                              )}
                            </div>
                            <div
                              className={`h-14 flex justify-center items-center ${
                                (selectedCoupon || createCoupon) && "hidden"
                              }`}
                            >
                              <div
                                className={`bg-[${
                                  coupon.status === "active"
                                    ? "rgb(59,176,101)"
                                    : coupon.status === "inactive"
                                    ? "#c15b78"
                                    : "#37beb0"
                                }] text-white font-semibold rounded-md px-2 py-1 `}
                              >
                                <p>
                                  {constant.statusesTranslate[coupon.status]}
                                </p>
                              </div>
                            </div>
                          </div>
                          {couponData.voucher?.length !== index + 1 && (
                            <Separator className="m-0 p-0" />
                          )}
                        </div>
                      ))}
                  </div>
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          </ScrollArea>
        ) : (
          <div className="w-full h-[50dvh] flex justify-center items-center">
            <Loading />
          </div>
        )}
      </Container>

      <div className="flex-grow flex flex-col gap-3">
        {selectedCoupon && (
          <EditCoupon
            selectedCoupon={selectedCoupon}
            fetchCoupon={fetchCoupon}
            setSelectedCoupon={setSelectedCoupon}
          />
        )}
        {createCoupon && (
          <CreateCoupon
            createCoupon={createCoupon}
            setCreateCoupon={setCreateCoupon}
            fetchCoupon={fetchCoupon}
          />
        )}
      </div>
    </div>
  );
};

const EditCoupon = ({ selectedCoupon, fetchCoupon, setSelectedCoupon }) => {
  const { callApi, updateConfig, configs } = Context();
  const { toast } = useToast();
  const [body, setBody] = useState(selectedCoupon);

  const [image, setImage] = useState(selectedCoupon.image);
  const [previewCoverImage, setPreviewCoverImage] = useState(
    selectedCoupon.previewImage
  );
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setBody(selectedCoupon);
    setImage(selectedCoupon.image);
    setPreviewCoverImage(selectedCoupon.previewImage);
  }, [selectedCoupon]);

  const setDate = (date) => {
    setBody((prevState) => ({ ...prevState, expireAt: date }));
  };

  const handleEditCoupon = async () => {
    setLoading(true);
    const { status } = await callApi("coupons-editCoupon", body);

    if (status) {
      toast({
        className: "bg-[rgb(59,176,101)]",
        title: `อัพเดตข้อมูลสำเร็จ`,
      });

      fetchCoupon();
      setSelectedCoupon(false);
    } else {
      toast({
        className: "bg-[#c15b78]",
        title: `อัพเดตข้อมูลไม่สำเร็จ`,
      });
    }
    setLoading(false);
  };

  const deleteCoupon = async () => {
    setLoading(true);
    const { status } = await callApi("coupons-editCoupon", {
      ...selectedCoupon,
      status: "removed",
    });

    if (status) {
      toast({
        className: "bg-[rgb(59,176,101)]",
        title: `อัพเดตข้อมูลสำเร็จ`,
      });

      fetchCoupon();
      setSelectedCoupon(false);
    } else {
      toast({
        className: "bg-[#c15b78]",
        title: `อัพเดตข้อมูลไม่สำเร็จ`,
      });
    }
    setLoading(false);
  };

  return (
    <Container className={`flex-grow duration-300 `}>
      <Text className="text-2xl font-semibold">แก้ไขคูปอง</Text>
      <div className="mt-12 flex w-full items-center gap-3 justify-evenly">
        {previewCoverImage ? (
          <div className="flex flex-col justify-center items-center gap-2">
            <img
              src={previewCoverImage || selectedCoupon.previewImage}
              className="max-w-[300px] max-h-[500px]"
              alt="preview"
            />
            <button
              className={`h-10 w-fit px-2 bg-[#c15b78] flex gap-1 justify-center items-center text-sm text-white hover:opacity-85 duration-200 disabled:hover:bg-gray-950 font-bold rounded-[0.5rem] disabled:cursor-not-allowed disabled:opacity-50`}
              onClick={() => setPreviewCoverImage(false)}
            >
              อัปโหลดรูปปก
            </button>
          </div>
        ) : (
          <label htmlFor="image">
            <div className="flex flex-col w-fit h-fit bg-slate-400 text-slate-200 hover:opacity-75 cursor-pointer duration-150 gap-2 p-4 items-center justify-center rounded-lg ">
              <FaRegImages className="w-40 h-40" />
              <p className="font-semibold">อัปโหลดรูปปก</p>
            </div>
          </label>
        )}
        {image ? (
          <div className="flex flex-col justify-center items-center gap-2">
            <img
              src={image || selectedCoupon.image}
              className="max-w-[300px] max-h-[500px]"
              alt="preview"
            />
            <button
              className={`h-10 w-fit px-2 bg-[#c15b78] flex gap-1 justify-center items-center text-sm text-white hover:opacity-85 duration-200 disabled:hover:bg-gray-950 font-bold rounded-[0.5rem] disabled:cursor-not-allowed disabled:opacity-50`}
              onClick={() => setImage(false)}
            >
              อัปโหลดรูปคูปอง
            </button>
          </div>
        ) : (
          <label htmlFor="coverImage">
            <div className="flex flex-col w-fit h-fit bg-slate-400 text-slate-200 hover:opacity-75 cursor-pointer duration-150 gap-2 p-4 items-center justify-center rounded-lg ">
              <BiImageAdd className="w-40 h-40" />
              <p className="font-semibold">อัปโหลดรูปคูปอง</p>
            </div>
          </label>
        )}
        <input
          style={{
            marginBottom: "15px",
            visibility: "hidden",
            position: "absolute",
            display: "none",
          }}
          id="image"
          type="file"
          onChange={async (e) => {
            const file = e.target.files[0];
            const previewImage64 = await resizeImage(file);
            if (previewImage64) {
              const previewUrl = URL.createObjectURL(file);
              setPreviewCoverImage(previewUrl);
              setBody((prevState) => ({
                ...prevState,
                previewImage: previewImage64,
              }));
            } else {
              alert("อัพโหลด ได้เฉพาะ ไฟล์รูปภาพ นามสกุล jpg หรือ png");
            }
          }}
        />
        <input
          style={{
            marginBottom: "15px",
            visibility: "hidden",
            position: "absolute",
            display: "none",
          }}
          id="coverImage"
          type="file"
          onChange={async (e) => {
            const file = e.target.files[0];
            const image64 = await resizeImage(file);
            if (image64) {
              const previewUrl = URL.createObjectURL(file);
              setImage(previewUrl);

              setBody((prevState) => ({
                ...prevState,
                image: image64,
              }));
            } else {
              alert("อัพโหลด ได้เฉพาะ ไฟล์รูปภาพ นามสกุล jpg หรือ png");
            }
          }}
        />
      </div>
      <div className="mt-8 grid grid-cols-2 gap-2">
        <div>
          <Text className={`font-bold text-sm text-gray-600`}>สถานะ</Text>
          <Select
            onValueChange={(e) => {
              setBody((prevState) => ({ ...prevState, status: e }));
            }}
            defaultValue={body.status}
            disabled={isLoading}
          >
            <SelectTrigger className={`my-1 w-full text-md font-medium`}>
              <SelectValue className="text-gray-200" placeholder="โปรดเลือก" />
            </SelectTrigger>

            <SelectContent className="bg-black ">
              <SelectGroup>
                <SelectItem
                  className="text-lg font-medium text-white bg-gray-950 hover:bg-gray-600"
                  value="active"
                >
                  เปิดใช้งาน
                </SelectItem>
                <SelectItem
                  className="text-lg font-medium text-white bg-gray-950 hover:bg-gray-600"
                  value="offline"
                >
                  เปิดใช้งานหลังบ้านเท่านั้น
                </SelectItem>
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
        <div>
          <Text className={`font-bold text-sm text-gray-600`}>ชื่อคูปอง</Text>
          <Input
            value={body.name}
            onChange={(e) =>
              setBody((prevState) => ({ ...prevState, name: e.target.value }))
            }
            placeholder="ชื่อคูปอง..."
            disabled={isLoading}
          />
        </div>
        <div>
          <Text className={`font-bold text-sm text-gray-600`}>ประเภทคูปอง</Text>
          <Select
            onValueChange={(e) => {
              setBody((prevState) => ({ ...prevState, type: e }));
            }}
            disabled={isLoading}
          >
            <SelectTrigger className={`my-1 w-full text-md font-medium`}>
              <SelectValue className="text-gray-200" placeholder="โปรดเลือก" />
            </SelectTrigger>

            <SelectContent className="bg-black ">
              <SelectGroup>
                <SelectItem
                  className="text-lg font-medium text-white bg-gray-950 hover:bg-gray-600"
                  value="discount"
                >
                  ส่วนลด
                </SelectItem>
                <SelectItem
                  className="text-lg font-medium text-white bg-gray-950 hover:bg-gray-600"
                  value="voucher"
                >
                  วอยเชอร์
                </SelectItem>
                <SelectItem
                  className="text-lg font-medium text-white bg-gray-950 hover:bg-gray-600"
                  value="reserve"
                >
                  จองสิทธิ์
                </SelectItem>
                <SelectItem
                  className="text-lg font-medium text-white bg-gray-950 hover:bg-gray-600"
                  value="notify"
                >
                  แจ้งเตือน (รายงวด)
                </SelectItem>
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
        {body.type === "reserve" && (
          <div>
            <Text className={`font-bold text-sm text-gray-600`}>
              ประเภทการจองสิทธิ์
            </Text>
            <Select
              onValueChange={(e) => {
                setBody((prevState) => ({ ...prevState, type: e }));
              }}
              disabled={isLoading}
            >
              <SelectTrigger className={`my-1 w-full text-md font-medium`}>
                <SelectValue
                  className="text-gray-200"
                  placeholder="โปรดเลือก"
                />
              </SelectTrigger>

              <SelectContent className="bg-black ">
                <SelectGroup>
                  <SelectItem
                    className="text-lg font-medium text-white bg-gray-950 hover:bg-gray-600"
                    value="lastTwo"
                  >
                    เลขท้าย 2 ตัว
                  </SelectItem>
                  <SelectItem
                    className="text-lg font-medium text-white bg-gray-950 hover:bg-gray-600"
                    value="lastThree"
                  >
                    เลขท้าย 3 ตัว
                  </SelectItem>
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
        )}
        <div>
          <Text className={`font-bold text-sm text-gray-600`}>
            รูปแบบการชำระ
          </Text>
          <Select
            onValueChange={(e) => {
              setBody((prevState) => ({ ...prevState, isPointRedeem: e }));
            }}
            defaultValue={body.isPointRedeem}
            disabled={isLoading}
          >
            <SelectTrigger className={`my-1 w-full text-md font-medium`}>
              <SelectValue className="text-gray-200" placeholder="โปรดเลือก" />
            </SelectTrigger>

            <SelectContent className="bg-black ">
              <SelectGroup>
                <SelectItem
                  className="text-lg font-medium text-white bg-gray-950 hover:bg-gray-600"
                  value={false}
                >
                  โอนเงิน
                </SelectItem>
                <SelectItem
                  className="text-lg font-medium text-white bg-gray-950 hover:bg-gray-600"
                  value={true}
                >
                  แลกคะแนน
                </SelectItem>
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
        <div>
          <Text className={`font-bold text-sm text-gray-600`}>
            ราคาขายคูปอง {body?.isPointRedeem ? "(คะแนน)" : "(โอนเงิน)"}
          </Text>
          <Input
            type="number"
            value={body.price}
            disabled={isLoading}
            onChange={(e) =>
              setBody((prevState) => ({ ...prevState, price: e.target.value }))
            }
          />
        </div>
        {body.type === "discount" && (
          <div>
            <Text className={`font-bold text-sm text-gray-600`}>
              คูปองส่วนลด (บาท)
            </Text>
            <Input
              type="number"
              value={body.discount}
              disabled={isLoading}
              onChange={(e) =>
                setBody((prevState) => ({
                  ...prevState,
                  discount: e.target.value,
                }))
              }
            />
          </div>
        )}
        {body.type === "voucher" && (
          <div>
            <Text className={`font-bold text-sm text-gray-600`}>
              จำนวนใบฟรี
            </Text>
            <Input
              type="number"
              value={body.quantity}
              disabled={isLoading}
              onChange={(e) =>
                setBody((prevState) => ({
                  ...prevState,
                  quantity: e.target.value,
                }))
              }
            />
          </div>
        )}
        {body.type === "reserve" && (
          <div>
            <Text className={`font-bold text-sm text-gray-600`}>
              จำนวนสิทธิ์
            </Text>
            <Input
              type="number"
              value={body.quantity}
              disabled={isLoading}
              onChange={(e) =>
                setBody((prevState) => ({
                  ...prevState,
                  quantity: e.target.value,
                }))
              }
            />
          </div>
        )}
        {body.type === "reserve" && (
          <div>
            <Text className={`font-bold text-sm text-gray-600`}>
              จำนวนใบสูงสุดต่อ 1 สิทธิ์
            </Text>
            <Input
              type="number"
              value={body.limitPerUsed}
              disabled={isLoading}
              onChange={(e) =>
                setBody((prevState) => ({
                  ...prevState,
                  limitPerUsed: e.target.value,
                }))
              }
            />
          </div>
        )}
        {body.type !== "reserve" && (
          <div>
            <Text className={`font-bold text-sm text-gray-600`}>
              จำนวนซื้อขั้นต่ำเพื่อใช้คูปอง (ใบ)
            </Text>
            <Input
              type="number"
              value={body.minimum}
              disabled={isLoading}
              onChange={(e) =>
                setBody((prevState) => ({
                  ...prevState,
                  minimum: e.target.value,
                }))
              }
            />
          </div>
        )}
        <div>
          <Text className={`font-bold text-sm text-gray-600`}>วันหมดอายุ</Text>
          <Popover>
            <PopoverTrigger disabled={isLoading} className="w-full">
              <button
                variant={"outline"}
                className={`relative w-full flex p-2.5 border rounded-lg font-semibold bg-gray-50 hover:bg-gray-950 hover:text-gray-50
                               `}
              >
                <IoMdCalendar className="text-[20px] mr-2" />
                {new Date(body.expireAt).toLocaleDateString("th-TH", {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                })}
              </button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0">
              <Calendar
                mode="single"
                selected={new Date(body.expireAt)}
                onSelect={(d) => {
                  setBody((prevState) => ({
                    ...prevState,
                    expireAt: new Date(d).getTime(),
                    expireAtDate: unixToDateAndTime(new Date(d).getTime()),
                  }));
                }}
                initialFocus
              />
              <div className="p-3 border-t border-border">
                <TimePickerDemo
                  setDate={setDate}
                  date={new Date(body.expireAt)}
                />
              </div>
            </PopoverContent>
          </Popover>
        </div>
      </div>
      <div className="flex justify-between gap-2 mt-8">
        <button
          className={`h-10 mr-2 w-fit px-3 text-[#c15b78] flex gap-1 justify-center items-center text-sm hover:opacity-85 duration-200 disabled:hover:bg-gray-950 font-bold rounded-[0.5rem] disabled:cursor-not-allowed disabled:opacity-50`}
          onClick={() => deleteCoupon(false)}
        >
          {isLoading ? <Loading className={`w-4 h-4`} /> : "ลบคูปอง"}
        </button>
        <div className="flex gap-2">
          <button
            className={`h-10 w-fit px-3 bg-[#c15b78] flex gap-1 justify-center items-center text-sm text-white hover:opacity-85 duration-200 disabled:hover:bg-gray-950 font-bold rounded-[0.5rem] disabled:cursor-not-allowed disabled:opacity-50`}
            onClick={() => setSelectedCoupon(false)}
          >
            ยกเลิก
          </button>
          <button
            className={`h-10 w-fit min-w-20 px-3 bg-[rgb(59,176,101)] flex gap-1 justify-center items-center text-sm text-white hover:opacity-85 duration-200 disabled:hover:bg-gray-950 font-bold rounded-[0.5rem] disabled:cursor-not-allowed disabled:opacity-50`}
            onClick={() => handleEditCoupon()}
          >
            {isLoading ? <Loading className={`w-4 h-4`} /> : "แก้ไขคูปอง"}
          </button>
        </div>
      </div>
    </Container>
  );
};

const CreateCoupon = ({ createCoupon, setCreateCoupon, fetchCoupon }) => {
  const { callApi, updateConfig, configs } = Context();
  const { toast } = useToast();
  const [isLoading, setLoading] = useState(false);
  const [body, setBody] = useState({
    image: undefined,
    status: "active",
    name: "",
    price: 0,
    isPointRedeem: false,
    type: "",
    discount: 0,
    quantity: 1,
    minimum: 0,
    expireAtDate: undefined,
    expireAt: Date.now(),
  });
  const [image, setImage] = useState(false);
  const [previewCoverImage, setPreviewCoverImage] = useState(false);

  useEffect(() => {
    setBody({
      image: undefined,
      status: "active",
      name: "",
      price: undefined,
      isPointRedeem: false,
      type: "",
      discount: 0,
      quantity: 1,
      minimum: 0,
      expireAtDate: undefined,
      expireAt: Date.now(),
    });
  }, [createCoupon]);

  const setDate = (date) => {
    setBody((prevState) => ({ ...prevState, expireAt: date }));
  };

  const handleCreateCoupon = async () => {
    setLoading(true);
    const { status } = await callApi("coupons-createCoupon", body);

    if (status) {
      toast({
        className: "bg-[rgb(59,176,101)]",
        title: `อัพเดตข้อมูลสำเร็จ`,
      });

      fetchCoupon();
      setCreateCoupon(false);
    } else {
      toast({
        className: "bg-[#c15b78]",
        title: `อัพเดตข้อมูลไม่สำเร็จ`,
      });
    }
    setLoading(false);
  };

  return (
    <Container className={`flex-grow duration-300 `}>
      <Text className="text-2xl font-semibold">สร้างคูปอง</Text>
      <div className="mt-12 flex items-center justify-evenly">
        {previewCoverImage ? (
          <div className="flex flex-col justify-center items-center gap-2">
            <img
              src={previewCoverImage}
              className="max-w-[300px] max-h-[500px]"
              alt="preview"
            />
            <button
              className={`h-10 w-fit px-2 bg-[#c15b78] flex gap-1 justify-center items-center text-sm text-white hover:opacity-85 duration-200 disabled:hover:bg-gray-950 font-bold rounded-[0.5rem] disabled:cursor-not-allowed disabled:opacity-50`}
              onClick={() => setPreviewCoverImage(false)}
            >
              อัปโหลดรูปปก
            </button>
          </div>
        ) : (
          <label htmlFor="createCoverImage">
            <div className="flex flex-col w-fit h-fit bg-slate-400 text-slate-200 hover:opacity-75 cursor-pointer duration-150 gap-2 p-4 items-center justify-center rounded-lg ">
              <FaRegImages className="w-40 h-40" />
              <p className="font-semibold">อัปโหลดรูปปก</p>
            </div>
          </label>
        )}
        {image ? (
          <div className="flex flex-col justify-center items-center gap-2">
            <img
              src={image}
              className="max-w-[300px] max-h-[500px]"
              alt="preview"
            />
            <button
              className={`h-10 w-fit px-2 bg-[#c15b78] flex gap-1 justify-center items-center text-sm text-white hover:opacity-85 duration-200 disabled:hover:bg-gray-950 font-bold rounded-[0.5rem] disabled:cursor-not-allowed disabled:opacity-50`}
              onClick={() => setImage(false)}
              disabled={isLoading}
            >
              อัปโหลดรูปใหม่
            </button>
          </div>
        ) : (
          <label htmlFor="createImage">
            <div className="flex flex-col w-fit h-fit bg-slate-400 text-slate-200 hover:opacity-75 cursor-pointer duration-150 gap-2 p-4 items-center justify-center rounded-lg ">
              <BiImageAdd className="w-40 h-40" />
              <p className="font-semibold">อัปโหลดรูป</p>
            </div>
          </label>
        )}
        <input
          style={{
            marginBottom: "15px",
            visibility: "hidden",
            position: "absolute",
            display: "none",
          }}
          id="createImage"
          type="file"
          onChange={async (e) => {
            const file = e.target.files[0];
            const base64 = await resizeImage(file);
            if (base64) {
              const previewUrl = URL.createObjectURL(file);
              setImage(previewUrl);
              setBody((prevState) => ({ ...prevState, image: base64 }));
            } else {
              alert("อัพโหลด ได้เฉพาะ ไฟล์รูปภาพ นามสกุล jpg หรือ png");
            }
          }}
        />
        <input
          style={{
            marginBottom: "15px",
            visibility: "hidden",
            position: "absolute",
            display: "none",
          }}
          id="createCoverImage"
          type="file"
          onChange={async (e) => {
            const file = e.target.files[0];
            const base64 = await resizeImage(file);
            if (base64) {
              const previewUrl = URL.createObjectURL(file);
              setPreviewCoverImage(previewUrl);
              setBody((prevState) => ({ ...prevState, previewImage: base64 }));
            } else {
              alert("อัพโหลด ได้เฉพาะ ไฟล์รูปภาพ นามสกุล jpg หรือ png");
            }
          }}
        />
      </div>
      <div className="mt-8 grid grid-cols-2 gap-2">
        <div>
          <Text className={`font-bold text-sm text-gray-600`}>สถานะ</Text>
          <Select
            onValueChange={(e) => {
              setBody((prevState) => ({ ...prevState, status: e }));
            }}
            disabled={isLoading}
            defaultValue={body.status}
          >
            <SelectTrigger className={`my-1 w-full text-md font-medium`}>
              <SelectValue className="text-gray-200" placeholder="โปรดเลือก" />
            </SelectTrigger>

            <SelectContent className="bg-black ">
              <SelectGroup>
                <SelectItem
                  className="text-lg font-medium text-white bg-gray-950 hover:bg-gray-600"
                  value="active"
                >
                  เปิดใช้งาน
                </SelectItem>
                <SelectItem
                  className="text-lg font-medium text-white bg-gray-950 hover:bg-gray-600"
                  value="offline"
                >
                  เปิดใช้งานหลังบ้านเท่านั้น
                </SelectItem>
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
        <div>
          <Text className={`font-bold text-sm text-gray-600`}>ชื่อคูปอง</Text>
          <Input
            value={body.name}
            onChange={(e) =>
              setBody((prevState) => ({ ...prevState, name: e.target.value }))
            }
            disabled={isLoading}
            placeholder="ชื่อคูปอง..."
          />
        </div>
        <div>
          <Text className={`font-bold text-sm text-gray-600`}>ประเภทคูปอง</Text>
          <Select
            onValueChange={(e) => {
              setBody((prevState) => ({ ...prevState, type: e }));
            }}
            disabled={isLoading}
          >
            <SelectTrigger className={`my-1 w-full text-md font-medium`}>
              <SelectValue className="text-gray-200" placeholder="โปรดเลือก" />
            </SelectTrigger>

            <SelectContent className="bg-black ">
              <SelectGroup>
                <SelectItem
                  className="text-lg font-medium text-white bg-gray-950 hover:bg-gray-600"
                  value="discount"
                >
                  ส่วนลด
                </SelectItem>
                <SelectItem
                  className="text-lg font-medium text-white bg-gray-950 hover:bg-gray-600"
                  value="voucher"
                >
                  วอยเชอร์
                </SelectItem>
                <SelectItem
                  className="text-lg font-medium text-white bg-gray-950 hover:bg-gray-600"
                  value="reserve"
                >
                  จองสิทธิ์
                </SelectItem>
                <SelectItem
                  className="text-lg font-medium text-white bg-gray-950 hover:bg-gray-600"
                  value="notify"
                >
                  แจ้งเตือน (รายงวด)
                </SelectItem>
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
        {body.type === "reserve" && (
          <div>
            <Text className={`font-bold text-sm text-gray-600`}>
              ประเภทการจองสิทธิ์
            </Text>
            <Select
              onValueChange={(e) => {
                setBody((prevState) => ({ ...prevState, type: e }));
              }}
              disabled={isLoading}
            >
              <SelectTrigger className={`my-1 w-full text-md font-medium`}>
                <SelectValue
                  className="text-gray-200"
                  placeholder="โปรดเลือก"
                />
              </SelectTrigger>

              <SelectContent className="bg-black ">
                <SelectGroup>
                  <SelectItem
                    className="text-lg font-medium text-white bg-gray-950 hover:bg-gray-600"
                    value="lastTwo"
                  >
                    เลขท้าย 2 ตัว
                  </SelectItem>
                  <SelectItem
                    className="text-lg font-medium text-white bg-gray-950 hover:bg-gray-600"
                    value="lastThree"
                  >
                    เลขท้าย 3 ตัว
                  </SelectItem>
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
        )}
        <div>
          <Text className={`font-bold text-sm text-gray-600`}>
            รูปแบบการชำระ
          </Text>
          <Select
            onValueChange={(e) => {
              setBody((prevState) => ({ ...prevState, isPointRedeem: e }));
            }}
            defaultValue={body.isPointRedeem}
            disabled={isLoading}
          >
            <SelectTrigger className={`my-1 w-full text-md font-medium`}>
              <SelectValue className="text-gray-200" placeholder="โปรดเลือก" />
            </SelectTrigger>

            <SelectContent className="bg-black ">
              <SelectGroup>
                <SelectItem
                  className="text-lg font-medium text-white bg-gray-950 hover:bg-gray-600"
                  value={false}
                >
                  โอนเงิน
                </SelectItem>
                <SelectItem
                  className="text-lg font-medium text-white bg-gray-950 hover:bg-gray-600"
                  value={true}
                >
                  แลกคะแนน
                </SelectItem>
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
        <div>
          <Text className={`font-bold text-sm text-gray-600`}>
            ราคาขายคูปอง {body?.isPointRedeem ? "(คะแนน)" : "(โอนเงิน)"}
          </Text>
          <Input
            type="number"
            value={body.price}
            disabled={isLoading}
            onChange={(e) =>
              setBody((prevState) => ({ ...prevState, price: e.target.value }))
            }
          />
        </div>
        {body.type === "discount" && (
          <div>
            <Text className={`font-bold text-sm text-gray-600`}>
              คูปองส่วนลด (บาท)
            </Text>
            <Input
              type="number"
              value={body.discount}
              disabled={isLoading}
              onChange={(e) =>
                setBody((prevState) => ({
                  ...prevState,
                  discount: e.target.value,
                }))
              }
            />
          </div>
        )}
        {body.type === "voucher" && (
          <div>
            <Text className={`font-bold text-sm text-gray-600`}>
              จำนวนใบฟรี
            </Text>
            <Input
              type="number"
              value={body.quantity}
              disabled={isLoading}
              onChange={(e) =>
                setBody((prevState) => ({
                  ...prevState,
                  quantity: e.target.value,
                }))
              }
            />
          </div>
        )}
        {body.type === "reserve" && (
          <div>
            <Text className={`font-bold text-sm text-gray-600`}>
              จำนวนสิทธิ์
            </Text>
            <Input
              type="number"
              value={body.quantity}
              disabled={isLoading}
              onChange={(e) =>
                setBody((prevState) => ({
                  ...prevState,
                  quantity: e.target.value,
                }))
              }
            />
          </div>
        )}
        {body.type === "reserve" && (
          <div>
            <Text className={`font-bold text-sm text-gray-600`}>
              จำนวนใบสูงสุดต่อ 1 สิทธิ์
            </Text>
            <Input
              type="number"
              value={body.limitPerUsed}
              disabled={isLoading}
              onChange={(e) =>
                setBody((prevState) => ({
                  ...prevState,
                  limitPerUsed: e.target.value,
                }))
              }
            />
          </div>
        )}
        {body.type !== "reserve" && (
          <div>
            <Text className={`font-bold text-sm text-gray-600`}>
              จำนวนซื้อขั้นต่ำเพื่อใช้คูปอง (ใบ)
            </Text>
            <Input
              type="number"
              value={body.minimum}
              disabled={isLoading}
              onChange={(e) =>
                setBody((prevState) => ({
                  ...prevState,
                  minimum: e.target.value,
                }))
              }
            />
          </div>
        )}
        <div>
          <Text className={`font-bold text-sm text-gray-600`}>วันหมดอายุ</Text>
          <Popover>
            <PopoverTrigger disabled={isLoading} className="w-full">
              <button
                variant={"outline"}
                className={`relative w-full flex p-2.5 border rounded-lg font-semibold bg-gray-50 hover:bg-gray-950 hover:text-gray-50
                             `}
              >
                <IoMdCalendar className="text-[20px] mr-2" />
                {new Date(body.expireAt).toLocaleDateString("th-TH", {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                })}
              </button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0">
              <Calendar
                mode="single"
                selected={new Date(body.expireAt)}
                onSelect={(d) => {
                  setBody((prevState) => ({
                    ...prevState,
                    expireAt: new Date(d).getTime(),
                    expireAtDate: unixToDateAndTime(new Date(d).getTime()),
                  }));
                }}
                initialFocus
              />
              <div className="p-3 border-t border-border">
                <TimePickerDemo
                  setDate={setDate}
                  date={new Date(body.expireAt)}
                />
              </div>
            </PopoverContent>
          </Popover>
        </div>
      </div>
      <div className="flex justify-end gap-2 mt-8">
        <button
          className={`h-10 w-fit px-3 bg-[#c15b78] flex gap-1 justify-center items-center text-sm text-white hover:opacity-85 duration-200 disabled:hover:bg-gray-950 font-bold rounded-[0.5rem] disabled:cursor-not-allowed disabled:opacity-50`}
          onClick={() => setCreateCoupon(false)}
        >
          ยกเลิก
        </button>
        <button
          className={`h-10 w-fit min-w-20 px-3 bg-[rgb(59,176,101)] flex gap-1 justify-center items-center text-sm text-white hover:opacity-85 duration-200 disabled:hover:bg-gray-950 font-bold rounded-[0.5rem] disabled:cursor-not-allowed disabled:opacity-50`}
          onClick={() => handleCreateCoupon()}
        >
          {isLoading ? <Loading className={`w-4 h-4`} /> : "สร้างคูปอง"}
        </button>
      </div>
    </Container>
  );
};
